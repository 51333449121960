import React, { Component } from 'react';
import mytvmovie_img from '../images/mytvmovie_img.png';
import Grid from '../../../../node_modules/semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import malaysiaweatherforecast_img from '../images/malaysiaweatherforecast_img.png';
import { Label } from 'semantic-ui-react'

export class WeatherForecastApp extends Component {
    static displayName = WeatherForecastApp.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        this.incrementCounter = this.incrementCounter.bind(this);
    }

    incrementCounter() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    render() {
        return (
            <Grid style={{margin:'2%'} }>
                <Grid.Row>
                    <Grid.Column style={{ textAlign: 'center' }}>
                        <img src={malaysiaweatherforecast_img} alt="malaysiaweatherforecast_img" />
                        <h1>NAMI Malaysia Weather Forecast</h1>
                    </Grid.Column>        
                </Grid.Row>
                <Grid.Row >
                    <Grid.Column width={16}>
                        <Label as='a' color='blue' tag style={{marginRight:'1%'} }>
                            Xamarin
                        </Label>
                        <Label as='a' color='red' tag>
                            Web REST API
                        </Label>
                    </Grid.Column >
                </Grid.Row>
                <Grid.Row >              
                    <div style={{ justifyContent: 'center' }}>
                        <p>Get real time weather forecast in Malaysia.</p>
                        <p>This apps was developed with Xamarin.  The information was fetched from MET MALAYSIA. The MET MALAYSIA API service is for those interested in general forecast,
                            warning and satellite images in their application. Get started with
                            <a href="https://api.met.gov.my/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> MET MALAYSIA.
                            </a>
                        </p>
                        <p style={{ textAlign: 'center', fontSize:'20px' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.nami.met_app" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                Available on Play Store
                            </a>
                        </p>

                    </div>
                </Grid.Row>
            </Grid>

        );
    }
}
