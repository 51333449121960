import React, { Component } from 'react';
import { CustomFooter } from './CustomFooter';
import { Aboutus } from './Home/Aboutus';
import { Main } from './Home/Main';
import { OurWork } from './Home/OurWork';
export class HomeBoard extends Component {
  static displayName = HomeBoard.name;

  render() {
      return (
          <div>
              <div class="section-full">

                  <section id="main">
                      <Main></Main>
                  </section>

                  <section id="/#aboutus">
                      <Aboutus></Aboutus>
                  </section>

                  {/*<section id="/#ourwork">*/}
                  {/*    <OurWork></OurWork>*/}
                  {/*</section>*/}


                  <section id="footer">
                      <CustomFooter></CustomFooter>
                  </section>
              </div>
          </div>
    );
  }
}
