import React, { Component } from 'react';
import weather_img from '../../images/weather_img.png';
import 'typeface-roboto';
import { CustomFooter } from '../CustomFooter';
import { Label, Segment } from 'semantic-ui-react';
import { datatypes, forecastByLocation, listofDistrict, listofState, listofTown, warningForecast } from '../../jsons/WeatherForecast';
import { last24hours, last24hoursbystate } from '../../jsons/APIM';
export class MalaysiaWeatherForecastService extends Component {
    static displayName = MalaysiaWeatherForecastService.name;
    constructor(props) {

        super(props);
        this.state = {
            baseUrl: "http://malaysiaweatherforecastservice.runasp.net",
            listofStates: JSON.stringify(listofState),
            listofDistrict: JSON.stringify(listofDistrict),
            listofTown: JSON.stringify(listofTown),
            forecastByLocationUrl: " /WeatherForecast/forecast/location/{locationId}?start_date={start_date}&end_date={end_date}",
            forecastByLocationRespond: JSON.stringify(forecastByLocation),
            warningForecastUrl: "/WeatherForecast/warning/{warning_type}?start_date={start_date}&end_date={end_date}",
            warningForecast: JSON.stringify(warningForecast),
            datatypes: JSON.stringify(datatypes),
            last24hours: JSON.stringify(last24hours),
            last24hoursByStateUrl: "/apim/caqm/last24hours/state/{state}",
            last24hoursByState: JSON.stringify(last24hoursbystate)
        }
        window.scrollTo(0, 0);

        let answers = document.querySelectorAll(".accordion");
        answers.forEach((event) => {
            event.addEventListener("click", () => {
                if (event.classList.contains("active")) {
                    event.classList.remove("active");
                } else {
                    event.classList.add("active");
                }
            });
        });
    }

  render() {
      return (
          <div>
              <div class="section-full">
                  <div class="paddingOurWorkDetail">
                      <section>
                          <img class="scaleOurWorkImage" src={weather_img} alt="weather_img" />
                          <h1 class="roboto-medium" style={{ color: 'white' }}>Malaysia Weather Forecast Service</h1>
                      </section>

                      <table id="tableWithoutHeader">
                          <tr>
                              <td>Technology used:</td>
                              <td>ASP.Net Core, C#</td>
                          </tr>
                          <tr>
                              <td>Description:</td>
                              <td>
                                  This Web Service API (Application Programming Interface) is a free service offered by Nami Studio to the general public. This API was fetched from
                                  Malaysian Meteorological Department exposes endpoints with JSON response. We offered general forecast data without any user authentication.
                                  Get started with  <a href="https://api.met.gov.my/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> MET API.</a>
                              </td>
                          </tr>
                          <tr>
                              <td colSpan="2" style={{ textAlign: 'center' }}>
                                  To start integrate this Malaysian Weather Forecast Service API, follow the documentation below.</td>
                          </tr>
                      </table>
                  </div>

                  <div class="paddingOurWorkScreenShort">
                      <h1 class="roboto-bold" style={{ textAlign: 'center' }}>DOCUMEN<font color="#FF001F">TATION</font></h1>
                      <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                      <table id="tableWithoutHeaderDark">
                          <tr>
                              <td>Base Url:</td>
                              <td>{this.state.baseUrl}</td>
                          </tr>
                          <tr>
                              <td>Data Interchange Format:</td>
                              <td>
                                  The supported data interchange format is JSON.
                              </td>
                          </tr>
                      </table>

                      <div>&nbsp;</div>
                      <div>&nbsp;</div>

                      <section>
                          <h3 class="roboto-bold">Weather Forecast</h3>
                          <div class="container">
                              <details>
                                  <summary>Get list of states in Malaysia</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          /WeatherForecast/listofStates
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.listofStates}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                              <details>
                                  <summary>Get list of district in Malaysia</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          /WeatherForecast/listofDistrict
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.listofDistrict}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                              <details>
                                  <summary>Get list of town in Malaysia</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          /WeatherForecast/listofTown
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.listofTown}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                              <details>
                                  <summary>Get weather forecast by location</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          {this.state.forecastByLocationUrl}
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              Where:
                                              <div>
                                                  locationId : location identifier , for example LOCATION:4
                                              </div>
                                              <div>
                                                  start_date : start date must be from today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                                              </div>
                                              <div>
                                                  end_date : end date must be equal or after today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                                              </div>
                                          </Segment>
                                          <h4>Example</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com/WeatherForecast/forecast/location/LOCATION:4?start_date=8/19/2023&end_date=8/20/2023</code>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.forecastByLocationRespond}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                              <details>
                                  <summary>Get datatypes</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          /WeatherForecast/datatypes
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.datatypes}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                          </div>
                      </section>

                      <section>
                          <h3 class="roboto-bold">General Warning</h3>
                          <div class="container">
                              <details>
                                  <summary>Get list of warning in Malaysia</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          {this.state.warningForecastUrl}
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              Where:
                                              <div>
                                                  warning_type : is a type of warning available in Malaysia. There are 5 types of warning
                                                  <li style={{ color: 'black', marginLeft: '2%' }}>quaketsunami</li>
                                                  <li style={{ color: 'black', marginLeft: '2%' }}>rain</li>
                                                  <li style={{ color: 'black', marginLeft: '2%' }}>thunderstorm</li>
                                                  <li style={{ color: 'black', marginLeft: '2%' }}>cyclone</li>
                                                  <li style={{ color: 'black', marginLeft: '2%' }}>windsea2</li>
                                              </div>
                                              <div>
                                                  start_date : start date must be from today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                                              </div>
                                              <div>
                                                  end_date : end date must be equal or after today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                                              </div>
                                          </Segment>
                                          <h4>Example</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com/WeatherForecast/warning/thunderstorm?start_date=8/19/2023&end_date=8/20/2023</code>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.warningForecast}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                          </div>
                      </section>

                      <section>
                          <h3 class="roboto-bold">Air Pollutant Index</h3>
                          <div class="container">
                              <details>
                                  <summary>Get Air Pollutant index for last 24 hours for all state</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          /apim/caqm/last24hours/all
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.last24hours}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                              <details>
                                  <summary>Get Air Pollutant index for last 24 hours by state</summary>
                                  <div>
                                      <Segment Raised>
                                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                                          {this.state.last24hoursByStateUrl}
                                          <h4>Parameter</h4>
                                          <Segment Raised>
                                              Where:
                                              <div>
                                                  state : name of any state in Malaysia, for example: kedah.
                                              </div>
                                          </Segment>
                                          <h4>Example</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com/apim/caqm/last24hours/state/kedah</code>
                                          </Segment>
                                          <h4>Response</h4>
                                          <Segment Raised>
                                              <code style={{ color: 'black' }}>{this.state.last24hoursByState}</code>
                                          </Segment>
                                      </Segment>
                                  </div>
                              </details>
                          </div>
                      </section>
                  </div>

                  <CustomFooter/>
              </div>
          </div>
    );
  }
}
