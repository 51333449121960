import React, { Component } from 'react';
import namiMetMalaysia from '../../images/malaysiaweatherforecast_img.png';
import 'typeface-roboto';
import { CustomFooter } from '../CustomFooter';
import ss1 from '../../images/NamiMetMalaysia/1.png';
import ss2 from '../../images/NamiMetMalaysia/2.png';
import ss3 from '../../images/NamiMetMalaysia/3.png';
import ss4 from '../../images/NamiMetMalaysia/4.png';
import ss5 from '../../images/NamiMetMalaysia/5.png';
import ss6 from '../../images/NamiMetMalaysia/6.png';
import ss7 from '../../images/NamiMetMalaysia/7.png';
import ss8 from '../../images/NamiMetMalaysia/8.png';
import ss9 from '../../images/NamiMetMalaysia/9.png';

export class NamiMetMalaysia extends Component {
    static displayName = NamiMetMalaysia.name;
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

  render() {
      return (
          <div>
              <div class="section-full">
                  <div class="paddingOurWorkDetail">
                      <section>
                          <img class="scaleOurWorkImage" src={namiMetMalaysia} alt="namiMetMalaysia" />
                          <h1 class="roboto-medium" style={{ color: 'white' }}>NAMI MET Malaysia</h1>
                      </section>

                      <table id="tableWithoutHeader">
                          <tr>
                              <td>Technology used:</td>
                              <td>Xamarin, C#, Web Rest API</td>
                          </tr>
                          <tr>
                              <td>Description:</td>
                              <td>Get real time weather forecast in Malaysia.

                                  This apps was developed with Xamarin. The information was fetched from MET MALAYSIA. The MET MALAYSIA API service is for those interested in general forecast, warning and satellite images in their application. Get started with MET MALAYSIA.</td>
                          </tr>
                          <tr>
                              <td colSpan="2" style={{ textAlign: 'center' }}> <a class="ourWorkLink" href="https://play.google.com/store/apps/details?id=com.nami.met_app" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                  Available on Play Store
                              </a></td>
                          </tr>
                      </table>
                  </div>

                  <div class="paddingOurWorkScreenShort">
                      <h1 class="roboto-bold" style={{ textAlign: 'center' }}>SCREEN <font color="#FF001F">SHORT</font></h1>
                      <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                      <div class="row" style={{ placeContent: 'center' }} >
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss1} alt="ss1" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss2} alt="ss2" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss3} alt="ss3" /></td>
                                  </tr>
                              </div>
                          </div>
                      </div>

                      <div class="row" style={{ placeContent: 'center' }} >
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss4} alt="ss4" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss5} alt="ss5" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss6} alt="ss6" /></td>
                                  </tr>
                              </div>
                          </div>
                      </div>

                      <div class="row" style={{ placeContent: 'center' }} >
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss7} alt="ss7" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss8} alt="ss8" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss9} alt="ss9" /></td>
                                  </tr>
                              </div>
                          </div>
                      </div>
                  </div>

                  <CustomFooter/>
              </div>
          </div>
    );
  }
}
