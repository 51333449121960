import React, { Component } from 'react';
import { CustomFooter } from '../CustomFooter';
import nchatApp from '../../images/nchatapp_img.png';

export class NChatApp extends Component {
    static displayName = NChatApp.name;

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            //<Grid style={{margin:'2%'} }>
            //    <Grid.Row>
            //        <Grid.Column style={{ textAlign: 'center' }}>
            //            <iframe
            //                width="100%"
            //                height="480"
            //                src={`https://www.youtube.com/embed/VZ7wXrKZMwA`}
            //                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            //                allowFullScreen
            //                title="Embedded youtube"
            //            />
            //            <h1>NChatApp</h1>
            //        </Grid.Column>
            //    </Grid.Row>
            //    <Grid.Row >
            //        <Grid.Column width={16}>
            //            <Label as='a' color='blue' tag style={{marginRight:'1%'} }>
            //                Xamarin
            //            </Label>
            //            <Label as='a' color='yellow' tag style={{ marginRight: '1%' }}>
            //                Firebase
            //            </Label>
            //        </Grid.Column >
            //    </Grid.Row>
            //    <Grid.Row >
            //        <div style={{ justifyContent: 'center' }}>
            //            <p> Basic Chat App</p>
            //            <p>Sent and received message, get message notification, change profile picture and check contact online status. This application developed with Xamarin and basic firebase integration.
            //            </p>
            //        </div>
            //    </Grid.Row>
            //</Grid>
            <div>
                <div class="section-full">
                    <div class="paddingOurWorkDetail">
                        <section>
                            <img class="scaleOurWorkImage" src={nchatApp} alt="nchatApp" />
                            <h1 class="roboto-medium" style={{ color: 'white' }}>Nchat-App</h1>
                        </section>

                        <table id="tableWithoutHeader">
                            <tr>
                                <td>Technology used:</td>
                                <td>Xamarin, C#, Firebase</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>Basic Chat App
                                    Sent and received message, get message notification, change profile picture and check contact online status.
                                    This application developed with Xamarin and basic firebase integration.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'center' }}> <a class="ourWorkLink" href="https://www.youtube.com/embed/VZ7wXrKZMwA" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                    Watch Demo
                                </a></td>
                            </tr>
                        </table>
                    </div>

                    <div class="paddingOurWorkScreenShort">
                        <h1 class="roboto-bold" style={{ textAlign: 'center' }}>WATCH <font color="#FF001F">DEMO</font></h1>
                        <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                        <div class="row" style={{ placeContent: 'center' }} >
                            <iframe
                           width="100%"
                            height="480"
                            src={`https://www.youtube.com/embed/VZ7wXrKZMwA`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                        </div>

                    </div>

                    <CustomFooter />
                </div>
            </div>

        );
    }
}
