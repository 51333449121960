import React, { Component } from 'react';
import dbsqlxmlconverter_img from '../../images/dbsqlxmlconverter_img.png';
import ss1 from '../../images/DbsqlxmlCon/1.png';
import ss2 from '../../images/DbsqlxmlCon/2.png';
import ss3 from '../../images/DbsqlxmlCon/3.png';
import ss4 from '../../images/DbsqlxmlCon/4.png';
import ss5 from '../../images/DbsqlxmlCon/5.png';
import { CustomFooter } from '../CustomFooter';

export class DBSQLXMLConverter extends Component {
    static displayName = DBSQLXMLConverter.name;

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <div class="section-full">
                    <div class="paddingOurWorkDetail">
                        <section>
                            <img class="scaleOurWorkImage" src={dbsqlxmlconverter_img} alt="dbsqlxmlconverter_img" />
                            <h1 class="roboto-medium" style={{ color: 'white' }}>DBSQLXML Converter</h1>
                        </section>

                        <table id="tableWithoutHeader">
                            <tr>
                                <td>Technology used:</td>
                                <td>Xamarin, C#</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>Convert any supported database file into XML file.
                                    You can easily convert your database to XML files anywhere and anytime. It's free!!
                                    Open the application, choose your storage location, search your database file and wait for a few second ~up
                                    to a minute depend on your file size. View the result, save the result and open the result in any supported application.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'center' }}> <a class="ourWorkLink" href="https://play.google.com/store/apps/details?id=nami.apps.dbsql_xml_converter" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                    Available on Play Store
                                </a></td>
                            </tr>
                        </table>
                    </div>

                    <div class="paddingOurWorkScreenShort">
                        <h1 class="roboto-bold" style={{ textAlign: 'center' }}>SCREEN <font color="#FF001F">SHORT</font></h1>
                        <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss1} alt="ss1" /></td>
                                    </tr>
                                </div>
                            </div>
                            <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss2} alt="ss2" /></td>
                                    </tr>
                                </div>
                            </div>
                            <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss3} alt="ss3" /></td>
                                    </tr>
                                </div>
                            </div>
                        </div>

                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss4} alt="ss4" /></td>
                                    </tr>
                                </div>
                            </div>
                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss5} alt="ss5" /></td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <CustomFooter />
                </div>
            </div>
           
        );
    }
}
