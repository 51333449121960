import React, { Component } from 'react';
import simpleTodo_img from '../images/simple_todo.png';
import Grid from '../../../../node_modules/semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import { Label } from 'semantic-ui-react'

export class SimpleTodoBoard extends Component {
    static displayName = SimpleTodoBoard.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        this.incrementCounter = this.incrementCounter.bind(this);
    }

    incrementCounter() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    render() {
        return (
            <Grid style={{margin:'2%'} }>
                <Grid.Row>
                    <Grid.Column style={{ textAlign: 'center', height: 'fit-content' }}>
                        <img src={simpleTodo_img} alt="simpleTodo_img" style={{ width: '50%', height: '50%' }} />
                        <h1>Simple Todo</h1>
                    </Grid.Column>        
                </Grid.Row>
                <Grid.Row >
                    <Grid.Column width={16}>
                        <Label as='a' color='blue' tag style={{marginRight:'1%'} }>
                            Xamarin
                        </Label>
                    </Grid.Column >
                </Grid.Row>
                <Grid.Row >              
                    <div style={{ justifyContent: 'center' }}>
                        <p> A simple daily todo task with basic operation ( add, update, and delete ) made with Xamarin Forms.</p>
                        <p>This is open source application just for learning purpose.
                        </p>
                        <p style={{ textAlign: 'center', fontSize:'20px' }}>
                            <a href="https://github.com/athiraIsam/SimpleTodoTask" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                               Get the source code here
                            </a>
                        </p>

                    </div>
                </Grid.Row>
            </Grid>

        );
    }
}
