import React, { Component } from 'react';

export class CustomFooter extends Component {
    static displayName = CustomFooter.name;

  render() {
      return (
          <div>
              <footer class="margin-footer" style={{ backgroundColor: "4A3536" }}>
                  <p class="roboto-bold">
                      Copyright &copy; {2022}. Made with fun by Nami Studio MY.
                  </p>
              </footer>
          </div>
    );
  }
}
