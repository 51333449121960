export const last24hours = 
    [
        {
            "state": "State",
            "location": "Location",
            "time1": "9:00PM",
            "time2": "10:00PM",
            "time3": "11:00PM",
            "time4": "12:00AM",
            "time5": "1:00AM",
            "time6": "2:00AM",
            "time7": "3:00AM",
            "time8": "4:00AM",
            "time9": "5:00AM",
            "time10": "6:00AM",
            "time11": "7:00AM",
            "time12": "8:00AM",
            "time13": "9:00AM",
            "time14": "10:00AM",
            "time15": "11:00AM",
            "time16": "12:00PM",
            "time17": "1:00PM",
            "time18": "2:00PM",
            "time19": "3:00PM",
            "time20": "4:00PM",
            "time21": "5:00PM",
            "time22": "6:00PM",
            "time23": "7:00PM",
            "time24": "8:00PM"
        },
        {
            "state": "PERLIS",
            "location": "Kangar",
            "time1": "20**",
            "time2": "20**",
            "time3": "19**",
            "time4": "18**",
            "time5": "18**",
            "time6": "17**",
            "time7": "17**",
            "time8": "17**",
            "time9": "16**",
            "time10": "16**",
            "time11": "17**",
            "time12": "16**",
            "time13": "16**",
            "time14": "16**",
            "time15": "16**",
            "time16": "16**",
            "time17": "16**",
            "time18": "17**",
            "time19": "18**",
            "time20": "18**",
            "time21": "19**",
            "time22": "20**",
            "time23": "21**",
            "time24": "21**"
        },
        {
            "state": "KEDAH",
            "location": "Langkawi",
            "time1": "21**",
            "time2": "21**",
            "time3": "22**",
            "time4": "24**",
            "time5": "26**",
            "time6": "26**",
            "time7": "26**",
            "time8": "25**",
            "time9": "25**",
            "time10": "25**",
            "time11": "25**",
            "time12": "26**",
            "time13": "27**",
            "time14": "27**",
            "time15": "28**",
            "time16": "29**",
            "time17": "28**",
            "time18": "28**",
            "time19": "28**",
            "time20": "29**",
            "time21": "29**",
            "time22": "29**",
            "time23": "31**",
            "time24": "30**"
        },
        {
            "state": "KEDAH",
            "location": "Alor Setar",
            "time1": "29**",
            "time2": "28**",
            "time3": "28**",
            "time4": "30**",
            "time5": "31**",
            "time6": "30**",
            "time7": "29**",
            "time8": "28**",
            "time9": "29**",
            "time10": "29**",
            "time11": "28**",
            "time12": "29**",
            "time13": "28**",
            "time14": "29**",
            "time15": "29**",
            "time16": "29**",
            "time17": "30**",
            "time18": "30**",
            "time19": "29**",
            "time20": "30**",
            "time21": "30**",
            "time22": "31**",
            "time23": "32**",
            "time24": "31**"
        },
        {
            "state": "KEDAH",
            "location": "Sungai Petani",
            "time1": "44**",
            "time2": "44**",
            "time3": "44**",
            "time4": "41**",
            "time5": "43**",
            "time6": "43**",
            "time7": "44**",
            "time8": "45**",
            "time9": "46**",
            "time10": "47**",
            "time11": "46**",
            "time12": "45**",
            "time13": "45**",
            "time14": "45**",
            "time15": "45**",
            "time16": "45**",
            "time17": "44**",
            "time18": "45**",
            "time19": "44**",
            "time20": "45**",
            "time21": "45**",
            "time22": "45**",
            "time23": "45**",
            "time24": "45**"
        },
        {
            "state": "KEDAH",
            "location": "Kulim Hi-Tech",
            "time1": "37**",
            "time2": "38**",
            "time3": "39**",
            "time4": "38**",
            "time5": "37**",
            "time6": "37**",
            "time7": "37**",
            "time8": "37**",
            "time9": "37**",
            "time10": "37**",
            "time11": "38**",
            "time12": "38**",
            "time13": "36**",
            "time14": "36**",
            "time15": "36**",
            "time16": "37**",
            "time17": "35**",
            "time18": "36**",
            "time19": "38**",
            "time20": "37**",
            "time21": "37**",
            "time22": "37**",
            "time23": "39**",
            "time24": "38**"
        },
        {
            "state": "PULAU PINANG",
            "location": "Seberang Jaya",
            "time1": "57**",
            "time2": "56**",
            "time3": "54**",
            "time4": "54**",
            "time5": "53**",
            "time6": "53**",
            "time7": "53**",
            "time8": "53**",
            "time9": "53**",
            "time10": "53**",
            "time11": "53**",
            "time12": "52**",
            "time13": "52**",
            "time14": "52**",
            "time15": "51**",
            "time16": "50**",
            "time17": "50**",
            "time18": "49**",
            "time19": "47**",
            "time20": "46**",
            "time21": "45**",
            "time22": "44**",
            "time23": "46**",
            "time24": "46**"
        },
        {
            "state": "PULAU PINANG",
            "location": "Seberang Perai",
            "time1": "51**",
            "time2": "52**",
            "time3": "49**",
            "time4": "48**",
            "time5": "50**",
            "time6": "50**",
            "time7": "51**",
            "time8": "51**",
            "time9": "51**",
            "time10": "52**",
            "time11": "52**",
            "time12": "52**",
            "time13": "52**",
            "time14": "49**",
            "time15": "50**",
            "time16": "50**",
            "time17": "49**",
            "time18": "49**",
            "time19": "49**",
            "time20": "49**",
            "time21": "48**",
            "time22": "47**",
            "time23": "46**",
            "time24": "45**"
        },
        {
            "state": "PULAU PINANG",
            "location": "Minden",
            "time1": "34**",
            "time2": "34**",
            "time3": "34**",
            "time4": "34**",
            "time5": "34**",
            "time6": "34**",
            "time7": "33**",
            "time8": "33**",
            "time9": "33**",
            "time10": "32**",
            "time11": "32**",
            "time12": "32**",
            "time13": "33**",
            "time14": "31**",
            "time15": "29**",
            "time16": "29**",
            "time17": "28**",
            "time18": "27**",
            "time19": "28**",
            "time20": "28**",
            "time21": "26**",
            "time22": "28**",
            "time23": "29**",
            "time24": "30**"
        },
        {
            "state": "PULAU PINANG",
            "location": "Balik Pulau",
            "time1": "34**",
            "time2": "33**",
            "time3": "32**",
            "time4": "31**",
            "time5": "31**",
            "time6": "30**",
            "time7": "30**",
            "time8": "31**",
            "time9": "30**",
            "time10": "30**",
            "time11": "30**",
            "time12": "30**",
            "time13": "31**",
            "time14": "31**",
            "time15": "29**",
            "time16": "29**",
            "time17": "28**",
            "time18": "29**",
            "time19": "30**",
            "time20": "28**",
            "time21": "27**",
            "time22": "27**",
            "time23": "28**",
            "time24": "29**"
        },
        {
            "state": "PERAK",
            "location": "Taiping",
            "time1": "46**",
            "time2": "46**",
            "time3": "46**",
            "time4": "46**",
            "time5": "46**",
            "time6": "45**",
            "time7": "45**",
            "time8": "44**",
            "time9": "45**",
            "time10": "44**",
            "time11": "43**",
            "time12": "43**",
            "time13": "44**",
            "time14": "44**",
            "time15": "44**",
            "time16": "46**",
            "time17": "47**",
            "time18": "47**",
            "time19": "47**",
            "time20": "47**",
            "time21": "47**",
            "time22": "42**",
            "time23": "46**",
            "time24": "45**"
        },
        {
            "state": "PERAK",
            "location": "Tasek Ipoh",
            "time1": "53**",
            "time2": "53**",
            "time3": "54**",
            "time4": "54**",
            "time5": "54**",
            "time6": "53**",
            "time7": "52**",
            "time8": "52**",
            "time9": "50**",
            "time10": "49**",
            "time11": "48**",
            "time12": "45**",
            "time13": "47**",
            "time14": "47**",
            "time15": "46**",
            "time16": "46**",
            "time17": "47**",
            "time18": "48**",
            "time19": "47**",
            "time20": "48**",
            "time21": "47**",
            "time22": "45**",
            "time23": "45**",
            "time24": "44**"
        },
        {
            "state": "PERAK",
            "location": "Pegoh Ipoh",
            "time1": "47**",
            "time2": "47**",
            "time3": "47**",
            "time4": "45**",
            "time5": "45**",
            "time6": "44**",
            "time7": "44**",
            "time8": "43**",
            "time9": "42**",
            "time10": "40**",
            "time11": "38**",
            "time12": "37**",
            "time13": "39**",
            "time14": "41**",
            "time15": "41**",
            "time16": "42**",
            "time17": "43**",
            "time18": "45**",
            "time19": "44**",
            "time20": "43**",
            "time21": "43**",
            "time22": "43**",
            "time23": "43**",
            "time24": "45**"
        },
        {
            "state": "PERAK",
            "location": "Seri Manjung",
            "time1": "53**",
            "time2": "53**",
            "time3": "53**",
            "time4": "52**",
            "time5": "53**",
            "time6": "52**",
            "time7": "52**",
            "time8": "52**",
            "time9": "51**",
            "time10": "50**",
            "time11": "47**",
            "time12": "46**",
            "time13": "47**",
            "time14": "44**",
            "time15": "44**",
            "time16": "44**",
            "time17": "46**",
            "time18": "45**",
            "time19": "45**",
            "time20": "44**",
            "time21": "44**",
            "time22": "44**",
            "time23": "42**",
            "time24": "43**"
        },
        {
            "state": "PERAK",
            "location": "Tanjung Malim",
            "time1": "31**",
            "time2": "30**",
            "time3": "29**",
            "time4": "30**",
            "time5": "29**",
            "time6": "30**",
            "time7": "30**",
            "time8": "30**",
            "time9": "30**",
            "time10": "31**",
            "time11": "31**",
            "time12": "32**",
            "time13": "32**",
            "time14": "32**",
            "time15": "32**",
            "time16": "31**",
            "time17": "32**",
            "time18": "32**",
            "time19": "33**",
            "time20": "33**",
            "time21": "35**",
            "time22": "35**",
            "time23": "35**",
            "time24": "35**"
        },
        {
            "state": "W.P. KUALA LUMPUR",
            "location": "Batu Muda",
            "time1": "61**",
            "time2": "61**",
            "time3": "61**",
            "time4": "61**",
            "time5": "61**",
            "time6": "61**",
            "time7": "61**",
            "time8": "61**",
            "time9": "61**",
            "time10": "61**",
            "time11": "61**",
            "time12": "61**",
            "time13": "61**",
            "time14": "62**",
            "time15": "62**",
            "time16": "61**",
            "time17": "61**",
            "time18": "61**",
            "time19": "61**",
            "time20": "61**",
            "time21": "61**",
            "time22": "61**",
            "time23": "60**",
            "time24": "59**"
        },
        {
            "state": "W.P. KUALA LUMPUR",
            "location": "Cheras",
            "time1": "59**",
            "time2": "59**",
            "time3": "60**",
            "time4": "60**",
            "time5": "60**",
            "time6": "60**",
            "time7": "59**",
            "time8": "59**",
            "time9": "58**",
            "time10": "58**",
            "time11": "58**",
            "time12": "58**",
            "time13": "58**",
            "time14": "58**",
            "time15": "58**",
            "time16": "57**",
            "time17": "56**",
            "time18": "56**",
            "time19": "57**",
            "time20": "56**",
            "time21": "56**",
            "time22": "55**",
            "time23": "54**",
            "time24": "53**"
        },
        {
            "state": "W.P. PUTRAJAYA",
            "location": "Putrajaya",
            "time1": "68**",
            "time2": "69**",
            "time3": "70**",
            "time4": "71**",
            "time5": "71**",
            "time6": "72**",
            "time7": "72**",
            "time8": "72**",
            "time9": "72**",
            "time10": "71**",
            "time11": "71**",
            "time12": "70**",
            "time13": "70**",
            "time14": "69**",
            "time15": "69**",
            "time16": "68**",
            "time17": "68**",
            "time18": "68**",
            "time19": "68**",
            "time20": "68**",
            "time21": "68**",
            "time22": "68**",
            "time23": "68**",
            "time24": "67**"
        },
        {
            "state": "SELANGOR",
            "location": "Kuala Selangor",
            "time1": "52**",
            "time2": "51**",
            "time3": "48**",
            "time4": "48**",
            "time5": "46**",
            "time6": "46**",
            "time7": "46**",
            "time8": "45**",
            "time9": "44**",
            "time10": "43**",
            "time11": "43**",
            "time12": "43**",
            "time13": "47**",
            "time14": "49**",
            "time15": "50**",
            "time16": "48**",
            "time17": "47**",
            "time18": "44**",
            "time19": "44**",
            "time20": "44**",
            "time21": "44**",
            "time22": "46**",
            "time23": "51**",
            "time24": "53**"
        },
        {
            "state": "SELANGOR",
            "location": "Petaling Jaya",
            "time1": "64**",
            "time2": "64**",
            "time3": "65**",
            "time4": "65**",
            "time5": "64**",
            "time6": "63**",
            "time7": "63**",
            "time8": "63**",
            "time9": "63**",
            "time10": "64**",
            "time11": "64**",
            "time12": "65**",
            "time13": "65**",
            "time14": "64**",
            "time15": "62**",
            "time16": "62**",
            "time17": "61**",
            "time18": "62**",
            "time19": "62**",
            "time20": "61**",
            "time21": "61**",
            "time22": "61**",
            "time23": "60**",
            "time24": "60**"
        },
        {
            "state": "SELANGOR",
            "location": "Shah Alam",
            "time1": "71**",
            "time2": "71**",
            "time3": "71**",
            "time4": "71**",
            "time5": "71**",
            "time6": "71**",
            "time7": "71**",
            "time8": "71**",
            "time9": "71**",
            "time10": "71**",
            "time11": "70**",
            "time12": "69**",
            "time13": "70**",
            "time14": "69**",
            "time15": "68**",
            "time16": "67**",
            "time17": "67**",
            "time18": "67**",
            "time19": "67**",
            "time20": "67**",
            "time21": "66**",
            "time22": "65**",
            "time23": "66**",
            "time24": "66**"
        },
        {
            "state": "SELANGOR",
            "location": "Klang",
            "time1": "60**",
            "time2": "60**",
            "time3": "60**",
            "time4": "59**",
            "time5": "58**",
            "time6": "58**",
            "time7": "58**",
            "time8": "59**",
            "time9": "58**",
            "time10": "57**",
            "time11": "57**",
            "time12": "58**",
            "time13": "58**",
            "time14": "57**",
            "time15": "57**",
            "time16": "57**",
            "time17": "56**",
            "time18": "56**",
            "time19": "56**",
            "time20": "56**",
            "time21": "56**",
            "time22": "56**",
            "time23": "56**",
            "time24": "56**"
        },
        {
            "state": "SELANGOR",
            "location": "Banting",
            "time1": "64**",
            "time2": "65**",
            "time3": "66**",
            "time4": "68**",
            "time5": "70**",
            "time6": "71**",
            "time7": "71**",
            "time8": "72**",
            "time9": "73**",
            "time10": "72**",
            "time11": "71**",
            "time12": "70**",
            "time13": "71**",
            "time14": "72**",
            "time15": "71**",
            "time16": "71**",
            "time17": "71**",
            "time18": "70**",
            "time19": "70**",
            "time20": "69**",
            "time21": "68**",
            "time22": "68**",
            "time23": "68**",
            "time24": "68**"
        },
        {
            "state": "NEGERI SEMBILAN",
            "location": "Nilai",
            "time1": "68**",
            "time2": "69**",
            "time3": "70**",
            "time4": "72**",
            "time5": "73**",
            "time6": "73**",
            "time7": "73**",
            "time8": "74**",
            "time9": "74**",
            "time10": "75**",
            "time11": "76**",
            "time12": "76**",
            "time13": "75**",
            "time14": "74**",
            "time15": "73**",
            "time16": "72**",
            "time17": "74**",
            "time18": "74**",
            "time19": "73**",
            "time20": "73**",
            "time21": "73**",
            "time22": "72**",
            "time23": "71**",
            "time24": "71**"
        },
        {
            "state": "NEGERI SEMBILAN",
            "location": "Seremban",
            "time1": "59**",
            "time2": "60**",
            "time3": "61**",
            "time4": "61**",
            "time5": "62**",
            "time6": "62**",
            "time7": "63**",
            "time8": "64**",
            "time9": "64**",
            "time10": "65**",
            "time11": "66**",
            "time12": "67**",
            "time13": "68**",
            "time14": "68**",
            "time15": "69**",
            "time16": "68**",
            "time17": "67**",
            "time18": "66**",
            "time19": "66**",
            "time20": "66**",
            "time21": "66**",
            "time22": "65**",
            "time23": "64**",
            "time24": "63**"
        },
        {
            "state": "NEGERI SEMBILAN",
            "location": "Port Dickson",
            "time1": "53**",
            "time2": "54**",
            "time3": "54**",
            "time4": "54**",
            "time5": "53**",
            "time6": "53**",
            "time7": "54**",
            "time8": "54**",
            "time9": "54**",
            "time10": "54**",
            "time11": "54**",
            "time12": "54**",
            "time13": "54**",
            "time14": "54**",
            "time15": "54**",
            "time16": "54**",
            "time17": "53**",
            "time18": "53**",
            "time19": "53**",
            "time20": "53**",
            "time21": "53**",
            "time22": "53**",
            "time23": "53**",
            "time24": "53**"
        },
        {
            "state": "MELAKA",
            "location": "Alor Gajah",
            "time1": "59**",
            "time2": "59**",
            "time3": "60**",
            "time4": "60**",
            "time5": "61**",
            "time6": "61**",
            "time7": "61**",
            "time8": "62**",
            "time9": "62**",
            "time10": "62**",
            "time11": "63**",
            "time12": "63**",
            "time13": "64**",
            "time14": "64**",
            "time15": "63**",
            "time16": "64**",
            "time17": "63**",
            "time18": "63**",
            "time19": "63**",
            "time20": "62**",
            "time21": "62**",
            "time22": "62**",
            "time23": "63**",
            "time24": "63**"
        },
        {
            "state": "MELAKA",
            "location": "Bukit Rambai",
            "time1": "65**",
            "time2": "65**",
            "time3": "66**",
            "time4": "66**",
            "time5": "67**",
            "time6": "68**",
            "time7": "70**",
            "time8": "71**",
            "time9": "71**",
            "time10": "71**",
            "time11": "71**",
            "time12": "70**",
            "time13": "71**",
            "time14": "70**",
            "time15": "71**",
            "time16": "71**",
            "time17": "71**",
            "time18": "72**",
            "time19": "73**",
            "time20": "74**",
            "time21": "75**",
            "time22": "75**",
            "time23": "76**",
            "time24": "77**"
        },
        {
            "state": "MELAKA",
            "location": "Bandaraya Melaka",
            "time1": "51**",
            "time2": "53**",
            "time3": "54**",
            "time4": "54**",
            "time5": "56**",
            "time6": "57**",
            "time7": "58**",
            "time8": "58**",
            "time9": "58**",
            "time10": "58**",
            "time11": "57**",
            "time12": "57**",
            "time13": "57**",
            "time14": "57**",
            "time15": "57**",
            "time16": "58**",
            "time17": "58**",
            "time18": "58**",
            "time19": "58**",
            "time20": "58**",
            "time21": "58**",
            "time22": "59**",
            "time23": "59**",
            "time24": "59**"
        },
        {
            "state": "JOHOR",
            "location": "Segamat",
            "time1": "60**",
            "time2": "61**",
            "time3": "61**",
            "time4": "61**",
            "time5": "61**",
            "time6": "61**",
            "time7": "62**",
            "time8": "62**",
            "time9": "62**",
            "time10": "61**",
            "time11": "61**",
            "time12": "61**",
            "time13": "61**",
            "time14": "61**",
            "time15": "62**",
            "time16": "61**",
            "time17": "60**",
            "time18": "60**",
            "time19": "60**",
            "time20": "60**",
            "time21": "60**",
            "time22": "59**",
            "time23": "59**",
            "time24": "59**"
        },
        {
            "state": "JOHOR",
            "location": "Batu Pahat",
            "time1": "57**",
            "time2": "56**",
            "time3": "55**",
            "time4": "54**",
            "time5": "54**",
            "time6": "54**",
            "time7": "54**",
            "time8": "54**",
            "time9": "54**",
            "time10": "54**",
            "time11": "55**",
            "time12": "55**",
            "time13": "54**",
            "time14": "54**",
            "time15": "53**",
            "time16": "53**",
            "time17": "53**",
            "time18": "52**",
            "time19": "52**",
            "time20": "52**",
            "time21": "52**",
            "time22": "52**",
            "time23": "51**",
            "time24": "52**"
        },
        {
            "state": "JOHOR",
            "location": "Kluang",
            "time1": "55**",
            "time2": "55**",
            "time3": "54**",
            "time4": "54**",
            "time5": "54**",
            "time6": "54**",
            "time7": "54**",
            "time8": "54**",
            "time9": "55**",
            "time10": "55**",
            "time11": "55**",
            "time12": "55**",
            "time13": "56**",
            "time14": "56**",
            "time15": "56**",
            "time16": "56**",
            "time17": "56**",
            "time18": "56**",
            "time19": "55**",
            "time20": "55**",
            "time21": "55**",
            "time22": "54**",
            "time23": "54**",
            "time24": "54**"
        },
        {
            "state": "JOHOR",
            "location": "Larkin",
            "time1": "67**",
            "time2": "67**",
            "time3": "66**",
            "time4": "66**",
            "time5": "65**",
            "time6": "65**",
            "time7": "65**",
            "time8": "66**",
            "time9": "67**",
            "time10": "68**",
            "time11": "68**",
            "time12": "69**",
            "time13": "69**",
            "time14": "70**",
            "time15": "71**",
            "time16": "72**",
            "time17": "73**",
            "time18": "73**",
            "time19": "72**",
            "time20": "72**",
            "time21": "72**",
            "time22": "73**",
            "time23": "73**",
            "time24": "73**"
        },
        {
            "state": "JOHOR",
            "location": "Pasir Gudang",
            "time1": "66**",
            "time2": "66**",
            "time3": "66**",
            "time4": "66**",
            "time5": "66**",
            "time6": "66**",
            "time7": "65**",
            "time8": "65**",
            "time9": "64**",
            "time10": "64**",
            "time11": "64**",
            "time12": "64**",
            "time13": "63**",
            "time14": "64**",
            "time15": "64**",
            "time16": "64**",
            "time17": "64**",
            "time18": "64**",
            "time19": "63**",
            "time20": "64**",
            "time21": "64**",
            "time22": "64**",
            "time23": "65**",
            "time24": "65**"
        },
        {
            "state": "JOHOR",
            "location": "Pengerang",
            "time1": "58**",
            "time2": "58**",
            "time3": "59**",
            "time4": "59**",
            "time5": "59**",
            "time6": "59**",
            "time7": "59**",
            "time8": "59**",
            "time9": "59**",
            "time10": "59**",
            "time11": "58**",
            "time12": "58**",
            "time13": "57**",
            "time14": "57**",
            "time15": "57**",
            "time16": "57**",
            "time17": "58**",
            "time18": "58**",
            "time19": "58**",
            "time20": "58**",
            "time21": "59**",
            "time22": "59**",
            "time23": "59**",
            "time24": "59**"
        },
        {
            "state": "JOHOR",
            "location": "Kota Tinggi",
            "time1": "61**",
            "time2": "61**",
            "time3": "62**",
            "time4": "62**",
            "time5": "61**",
            "time6": "63**",
            "time7": "63**",
            "time8": "62**",
            "time9": "62**",
            "time10": "62**",
            "time11": "62**",
            "time12": "62**",
            "time13": "62**",
            "time14": "60**",
            "time15": "60**",
            "time16": "60**",
            "time17": "60**",
            "time18": "60**",
            "time19": "59**",
            "time20": "59**",
            "time21": "59**",
            "time22": "59**",
            "time23": "58**",
            "time24": "58**"
        },
        {
            "state": "JOHOR",
            "location": "Tangkak",
            "time1": "64**",
            "time2": "64**",
            "time3": "63**",
            "time4": "62**",
            "time5": "61**",
            "time6": "59**",
            "time7": "57**",
            "time8": "56**",
            "time9": "56**",
            "time10": "56**",
            "time11": "56**",
            "time12": "56**",
            "time13": "56**",
            "time14": "56**",
            "time15": "56**",
            "time16": "56**",
            "time17": "56**",
            "time18": "55**",
            "time19": "56**",
            "time20": "56**",
            "time21": "55**",
            "time22": "56**",
            "time23": "56**",
            "time24": "56**"
        },
        {
            "state": "PAHANG",
            "location": "Rompin",
            "time1": "38**",
            "time2": "39**",
            "time3": "40**",
            "time4": "40**",
            "time5": "37**",
            "time6": "37**",
            "time7": "37**",
            "time8": "38**",
            "time9": "38**",
            "time10": "36**",
            "time11": "37**",
            "time12": "38**",
            "time13": "39**",
            "time14": "40**",
            "time15": "41**",
            "time16": "41**",
            "time17": "42**",
            "time18": "44**",
            "time19": "45**",
            "time20": "46**",
            "time21": "47**",
            "time22": "48**",
            "time23": "50**",
            "time24": "51**"
        },
        {
            "state": "PAHANG",
            "location": "Temerloh",
            "time1": "49**",
            "time2": "49**",
            "time3": "44**",
            "time4": "44**",
            "time5": "45**",
            "time6": "44**",
            "time7": "42**",
            "time8": "41**",
            "time9": "39**",
            "time10": "39**",
            "time11": "40**",
            "time12": "41**",
            "time13": "42**",
            "time14": "44**",
            "time15": "47**",
            "time16": "49**",
            "time17": "50**",
            "time18": "51**",
            "time19": "51**",
            "time20": "52**",
            "time21": "52**",
            "time22": "52**",
            "time23": "51**",
            "time24": "51**"
        },
        {
            "state": "PAHANG",
            "location": "Jerantut",
            "time1": "40**",
            "time2": "36**",
            "time3": "34**",
            "time4": "34**",
            "time5": "36**",
            "time6": "37**",
            "time7": "38**",
            "time8": "39**",
            "time9": "40**",
            "time10": "40**",
            "time11": "41**",
            "time12": "41**",
            "time13": "44**",
            "time14": "45**",
            "time15": "47**",
            "time16": "48**",
            "time17": "49**",
            "time18": "50**",
            "time19": "50**",
            "time20": "49**",
            "time21": "49**",
            "time22": "47**",
            "time23": "47**",
            "time24": "46**"
        },
        {
            "state": "PAHANG",
            "location": "Indera Mahkota Kuantan",
            "time1": "53**",
            "time2": "52**",
            "time3": "52**",
            "time4": "48**",
            "time5": "49**",
            "time6": "48**",
            "time7": "50**",
            "time8": "47**",
            "time9": "48**",
            "time10": "51**",
            "time11": "52**",
            "time12": "51**",
            "time13": "52**",
            "time14": "53**",
            "time15": "54**",
            "time16": "54**",
            "time17": "55**",
            "time18": "54**",
            "time19": "56**",
            "time20": "56**",
            "time21": "57**",
            "time22": "58**",
            "time23": "58**",
            "time24": "58**"
        },
        {
            "state": "PAHANG",
            "location": "Balok Baru Kuantan",
            "time1": "49**",
            "time2": "49**",
            "time3": "48**",
            "time4": "48**",
            "time5": "47**",
            "time6": "45**",
            "time7": "45**",
            "time8": "44**",
            "time9": "43**",
            "time10": "45**",
            "time11": "45**",
            "time12": "46**",
            "time13": "48**",
            "time14": "51**",
            "time15": "52**",
            "time16": "53**",
            "time17": "53**",
            "time18": "53**",
            "time19": "54**",
            "time20": "55**",
            "time21": "54**",
            "time22": "55**",
            "time23": "55**",
            "time24": "55**"
        },
        {
            "state": "TERENGGANU",
            "location": "Kemaman",
            "time1": "47**",
            "time2": "48**",
            "time3": "48**",
            "time4": "46**",
            "time5": "45**",
            "time6": "43**",
            "time7": "42**",
            "time8": "41**",
            "time9": "40**",
            "time10": "40**",
            "time11": "39**",
            "time12": "40**",
            "time13": "41**",
            "time14": "44**",
            "time15": "47**",
            "time16": "49**",
            "time17": "51**",
            "time18": "52**",
            "time19": "52**",
            "time20": "51**",
            "time21": "52**",
            "time22": "52**",
            "time23": "52**",
            "time24": "52**"
        },
        {
            "state": "TERENGGANU",
            "location": "Paka",
            "time1": "48**",
            "time2": "48**",
            "time3": "47**",
            "time4": "46**",
            "time5": "46**",
            "time6": "45**",
            "time7": "43**",
            "time8": "41**",
            "time9": "39**",
            "time10": "40**",
            "time11": "39**",
            "time12": "41**",
            "time13": "43**",
            "time14": "46**",
            "time15": "47**",
            "time16": "48**",
            "time17": "51**",
            "time18": "52**",
            "time19": "53**",
            "time20": "53**",
            "time21": "54**",
            "time22": "54**",
            "time23": "54**",
            "time24": "55**"
        },
        {
            "state": "TERENGGANU",
            "location": "Kuala Terengganu",
            "time1": "55**",
            "time2": "54**",
            "time3": "55**",
            "time4": "56**",
            "time5": "55**",
            "time6": "55**",
            "time7": "56**",
            "time8": "58**",
            "time9": "60**",
            "time10": "60**",
            "time11": "59**",
            "time12": "60**",
            "time13": "60**",
            "time14": "61**",
            "time15": "61**",
            "time16": "60**",
            "time17": "61**",
            "time18": "60**",
            "time19": "60**",
            "time20": "59**",
            "time21": "58**",
            "time22": "60**",
            "time23": "61**",
            "time24": "61**"
        },
        {
            "state": "TERENGGANU",
            "location": "Besut",
            "time1": "45**",
            "time2": "47**",
            "time3": "50**",
            "time4": "51**",
            "time5": "50**",
            "time6": "48**",
            "time7": "48**",
            "time8": "47**",
            "time9": "48**",
            "time10": "47**",
            "time11": "50**",
            "time12": "51**",
            "time13": "52**",
            "time14": "52**",
            "time15": "52**",
            "time16": "52**",
            "time17": "52**",
            "time18": "52**",
            "time19": "52**",
            "time20": "52**",
            "time21": "52**",
            "time22": "52**",
            "time23": "48**",
            "time24": "47**"
        },
        {
            "state": "KELANTAN",
            "location": "Tanah Merah",
            "time1": "53**",
            "time2": "53**",
            "time3": "52**",
            "time4": "52**",
            "time5": "52**",
            "time6": "51**",
            "time7": "50**",
            "time8": "48**",
            "time9": "47**",
            "time10": "46**",
            "time11": "45**",
            "time12": "45**",
            "time13": "44**",
            "time14": "45**",
            "time15": "43**",
            "time16": "41**",
            "time17": "42**",
            "time18": "43**",
            "time19": "45**",
            "time20": "44**",
            "time21": "46**",
            "time22": "46**",
            "time23": "46**",
            "time24": "43**"
        },
        {
            "state": "KELANTAN",
            "location": "Kota Bharu",
            "time1": "62**",
            "time2": "60**",
            "time3": "58**",
            "time4": "56**",
            "time5": "54**",
            "time6": "53**",
            "time7": "52**",
            "time8": "51**",
            "time9": "50**",
            "time10": "49**",
            "time11": "48**",
            "time12": "48**",
            "time13": "45**",
            "time14": "44**",
            "time15": "46**",
            "time16": "47**",
            "time17": "45**",
            "time18": "44**",
            "time19": "43**",
            "time20": "44**",
            "time21": "42**",
            "time22": "42**",
            "time23": "42**",
            "time24": "41**"
        },
        {
            "state": "SABAH",
            "location": "Tawau",
            "time1": "43**",
            "time2": "40**",
            "time3": "40**",
            "time4": "41**",
            "time5": "41**",
            "time6": "41**",
            "time7": "41**",
            "time8": "42**",
            "time9": "41**",
            "time10": "41**",
            "time11": "42**",
            "time12": "43**",
            "time13": "42**",
            "time14": "41**",
            "time15": "42**",
            "time16": "42**",
            "time17": "44**",
            "time18": "45**",
            "time19": "45**",
            "time20": "45**",
            "time21": "44**",
            "time22": "45**",
            "time23": "49**",
            "time24": "50**"
        },
        {
            "state": "SABAH",
            "location": "Sandakan",
            "time1": "51**",
            "time2": "51**",
            "time3": "51**",
            "time4": "50**",
            "time5": "48**",
            "time6": "47**",
            "time7": "48**",
            "time8": "47**",
            "time9": "47**",
            "time10": "47**",
            "time11": "46**",
            "time12": "44**",
            "time13": "44**",
            "time14": "43**",
            "time15": "42**",
            "time16": "41**",
            "time17": "39**",
            "time18": "38**",
            "time19": "38**",
            "time20": "37**",
            "time21": "37**",
            "time22": "37**",
            "time23": "39**",
            "time24": "41**"
        },
        {
            "state": "SABAH",
            "location": "Kota Kinabalu",
            "time1": "53**",
            "time2": "52**",
            "time3": "51**",
            "time4": "49**",
            "time5": "47**",
            "time6": "46**",
            "time7": "46**",
            "time8": "46**",
            "time9": "44**",
            "time10": "43**",
            "time11": "41**",
            "time12": "37**",
            "time13": "34**",
            "time14": "33**",
            "time15": "36**",
            "time16": "38**",
            "time17": "39**",
            "time18": "43**",
            "time19": "43**",
            "time20": "42**",
            "time21": "44**",
            "time22": "46**",
            "time23": "48**",
            "time24": "49**"
        },
        {
            "state": "SABAH",
            "location": "Kimanis",
            "time1": "54**",
            "time2": "53**",
            "time3": "52**",
            "time4": "51**",
            "time5": "51**",
            "time6": "49**",
            "time7": "46**",
            "time8": "44**",
            "time9": "41**",
            "time10": "40**",
            "time11": "39**",
            "time12": "37**",
            "time13": "36**",
            "time14": "35**",
            "time15": "36**",
            "time16": "37**",
            "time17": "37**",
            "time18": "36**",
            "time19": "39**",
            "time20": "40**",
            "time21": "40**",
            "time22": "42**",
            "time23": "44**",
            "time24": "49**"
        },
        {
            "state": "SABAH",
            "location": "Keningau",
            "time1": "N/A",
            "time2": "N/A",
            "time3": "N/A",
            "time4": "N/A",
            "time5": "N/A",
            "time6": "N/A",
            "time7": "N/A",
            "time8": "N/A",
            "time9": "N/A",
            "time10": "N/A",
            "time11": "N/A",
            "time12": "N/A",
            "time13": "N/A",
            "time14": "N/A",
            "time15": "N/A",
            "time16": "N/A",
            "time17": "N/A",
            "time18": "N/A",
            "time19": "N/A",
            "time20": "N/A",
            "time21": "N/A",
            "time22": "N/A",
            "time23": "N/A",
            "time24": "N/A"
        },
        {
            "state": "W.P. LABUAN",
            "location": "Labuan",
            "time1": "52**",
            "time2": "52**",
            "time3": "50**",
            "time4": "46**",
            "time5": "44**",
            "time6": "44**",
            "time7": "43**",
            "time8": "44**",
            "time9": "45**",
            "time10": "43**",
            "time11": "44**",
            "time12": "45**",
            "time13": "46**",
            "time14": "46**",
            "time15": "45**",
            "time16": "47**",
            "time17": "48**",
            "time18": "50**",
            "time19": "50**",
            "time20": "51**",
            "time21": "51**",
            "time22": "52**",
            "time23": "52**",
            "time24": "53**"
        },
        {
            "state": "SARAWAK",
            "location": "Limbang",
            "time1": "66**",
            "time2": "65**",
            "time3": "64**",
            "time4": "64**",
            "time5": "63**",
            "time6": "62**",
            "time7": "61**",
            "time8": "60**",
            "time9": "60**",
            "time10": "60**",
            "time11": "59**",
            "time12": "59**",
            "time13": "58**",
            "time14": "57**",
            "time15": "56**",
            "time16": "55**",
            "time17": "54**",
            "time18": "54**",
            "time19": "54**",
            "time20": "54**",
            "time21": "54**",
            "time22": "54**",
            "time23": "55**",
            "time24": "56**"
        },
        {
            "state": "SARAWAK",
            "location": "ILP Miri",
            "time1": "64**",
            "time2": "63**",
            "time3": "62**",
            "time4": "62**",
            "time5": "61**",
            "time6": "61**",
            "time7": "60**",
            "time8": "59**",
            "time9": "60**",
            "time10": "59**",
            "time11": "59**",
            "time12": "59**",
            "time13": "58**",
            "time14": "58**",
            "time15": "58**",
            "time16": "58**",
            "time17": "57**",
            "time18": "58**",
            "time19": "58**",
            "time20": "57**",
            "time21": "56**",
            "time22": "56**",
            "time23": "56**",
            "time24": "57**"
        },
        {
            "state": "SARAWAK",
            "location": "Miri",
            "time1": "72**",
            "time2": "72**",
            "time3": "72**",
            "time4": "71**",
            "time5": "68**",
            "time6": "66**",
            "time7": "63**",
            "time8": "61**",
            "time9": "61**",
            "time10": "61**",
            "time11": "60**",
            "time12": "59**",
            "time13": "58**",
            "time14": "59**",
            "time15": "59**",
            "time16": "58**",
            "time17": "58**",
            "time18": "58**",
            "time19": "58**",
            "time20": "59**",
            "time21": "58**",
            "time22": "58**",
            "time23": "59**",
            "time24": "59**"
        },
        {
            "state": "SARAWAK",
            "location": "Samalaju",
            "time1": "66**",
            "time2": "64**",
            "time3": "63**",
            "time4": "62**",
            "time5": "61**",
            "time6": "61**",
            "time7": "61**",
            "time8": "62**",
            "time9": "62**",
            "time10": "62**",
            "time11": "62**",
            "time12": "62**",
            "time13": "62**",
            "time14": "62**",
            "time15": "61**",
            "time16": "60**",
            "time17": "60**",
            "time18": "59**",
            "time19": "59**",
            "time20": "59**",
            "time21": "59**",
            "time22": "60**",
            "time23": "59**",
            "time24": "59**"
        },
        {
            "state": "SARAWAK",
            "location": "Bintulu",
            "time1": "70**",
            "time2": "68**",
            "time3": "67**",
            "time4": "68**",
            "time5": "67**",
            "time6": "66**",
            "time7": "65**",
            "time8": "65**",
            "time9": "64**",
            "time10": "64**",
            "time11": "63**",
            "time12": "63**",
            "time13": "62**",
            "time14": "61**",
            "time15": "61**",
            "time16": "60**",
            "time17": "59**",
            "time18": "58**",
            "time19": "57**",
            "time20": "57**",
            "time21": "56**",
            "time22": "56**",
            "time23": "56**",
            "time24": "57**"
        },
        {
            "state": "SARAWAK",
            "location": "Mukah",
            "time1": "59**",
            "time2": "59**",
            "time3": "59**",
            "time4": "59**",
            "time5": "59**",
            "time6": "59**",
            "time7": "59**",
            "time8": "59**",
            "time9": "59**",
            "time10": "59**",
            "time11": "59**",
            "time12": "58**",
            "time13": "57**",
            "time14": "57**",
            "time15": "56**",
            "time16": "55**",
            "time17": "55**",
            "time18": "54**",
            "time19": "53**",
            "time20": "52**",
            "time21": "52**",
            "time22": "52**",
            "time23": "52**",
            "time24": "53**"
        },
        {
            "state": "SARAWAK",
            "location": "Kapit",
            "time1": "56**",
            "time2": "56**",
            "time3": "56**",
            "time4": "56**",
            "time5": "56**",
            "time6": "56**",
            "time7": "56**",
            "time8": "56**",
            "time9": "56**",
            "time10": "56**",
            "time11": "56**",
            "time12": "56**",
            "time13": "56**",
            "time14": "56**",
            "time15": "56**",
            "time16": "56**",
            "time17": "56**",
            "time18": "55**",
            "time19": "54**",
            "time20": "52**",
            "time21": "52**",
            "time22": "52**",
            "time23": "53**",
            "time24": "54**"
        },
        {
            "state": "SARAWAK",
            "location": "Sibu",
            "time1": "55**",
            "time2": "56**",
            "time3": "56**",
            "time4": "56**",
            "time5": "56**",
            "time6": "56**",
            "time7": "56**",
            "time8": "56**",
            "time9": "56**",
            "time10": "55**",
            "time11": "55**",
            "time12": "55**",
            "time13": "55**",
            "time14": "56**",
            "time15": "55**",
            "time16": "54**",
            "time17": "53**",
            "time18": "52**",
            "time19": "52**",
            "time20": "52**",
            "time21": "52**",
            "time22": "52**",
            "time23": "52**",
            "time24": "53**"
        },
        {
            "state": "SARAWAK",
            "location": "Sarikei",
            "time1": "59**",
            "time2": "59**",
            "time3": "59**",
            "time4": "59**",
            "time5": "58**",
            "time6": "58**",
            "time7": "58**",
            "time8": "58**",
            "time9": "58**",
            "time10": "57**",
            "time11": "57**",
            "time12": "57**",
            "time13": "57**",
            "time14": "56**",
            "time15": "55**",
            "time16": "54**",
            "time17": "54**",
            "time18": "53**",
            "time19": "52**",
            "time20": "52**",
            "time21": "53**",
            "time22": "53**",
            "time23": "54**",
            "time24": "54**"
        },
        {
            "state": "SARAWAK",
            "location": "Sri Aman",
            "time1": "66**",
            "time2": "65**",
            "time3": "64**",
            "time4": "63**",
            "time5": "63**",
            "time6": "62**",
            "time7": "62**",
            "time8": "60**",
            "time9": "60**",
            "time10": "60**",
            "time11": "60**",
            "time12": "59**",
            "time13": "59**",
            "time14": "58**",
            "time15": "58**",
            "time16": "57**",
            "time17": "57**",
            "time18": "56**",
            "time19": "56**",
            "time20": "56**",
            "time21": "55**",
            "time22": "55**",
            "time23": "56**",
            "time24": "56**"
        },
        {
            "state": "SARAWAK",
            "location": "Samarahan",
            "time1": "62**",
            "time2": "61**",
            "time3": "61**",
            "time4": "61**",
            "time5": "60**",
            "time6": "60**",
            "time7": "60**",
            "time8": "60**",
            "time9": "59**",
            "time10": "59**",
            "time11": "59**",
            "time12": "60**",
            "time13": "59**",
            "time14": "58**",
            "time15": "58**",
            "time16": "57**",
            "time17": "56**",
            "time18": "56**",
            "time19": "56**",
            "time20": "57**",
            "time21": "58**",
            "time22": "58**",
            "time23": "58**",
            "time24": "58**"
        },
        {
            "state": "SARAWAK",
            "location": "Kuching",
            "time1": "71**",
            "time2": "71**",
            "time3": "70**",
            "time4": "70**",
            "time5": "70**",
            "time6": "69**",
            "time7": "69**",
            "time8": "69**",
            "time9": "69**",
            "time10": "68**",
            "time11": "68**",
            "time12": "68**",
            "time13": "67**",
            "time14": "67**",
            "time15": "66**",
            "time16": "65**",
            "time17": "64**",
            "time18": "63**",
            "time19": "62**",
            "time20": "62**",
            "time21": "62**",
            "time22": "63**",
            "time23": "64**",
            "time24": "64**"
        }
    ]
export const last24hoursbystate = [
    {
        "state": "State",
        "location": "Location",
        "time1": "9:00PM",
        "time2": "10:00PM",
        "time3": "11:00PM",
        "time4": "12:00AM",
        "time5": "1:00AM",
        "time6": "2:00AM",
        "time7": "3:00AM",
        "time8": "4:00AM",
        "time9": "5:00AM",
        "time10": "6:00AM",
        "time11": "7:00AM",
        "time12": "8:00AM",
        "time13": "9:00AM",
        "time14": "10:00AM",
        "time15": "11:00AM",
        "time16": "12:00PM",
        "time17": "1:00PM",
        "time18": "2:00PM",
        "time19": "3:00PM",
        "time20": "4:00PM",
        "time21": "5:00PM",
        "time22": "6:00PM",
        "time23": "7:00PM",
        "time24": "8:00PM"
    },
    {
        "state": "KEDAH",
        "location": "Langkawi",
        "time1": "21**",
        "time2": "21**",
        "time3": "22**",
        "time4": "24**",
        "time5": "26**",
        "time6": "26**",
        "time7": "26**",
        "time8": "25**",
        "time9": "25**",
        "time10": "25**",
        "time11": "25**",
        "time12": "26**",
        "time13": "27**",
        "time14": "27**",
        "time15": "28**",
        "time16": "29**",
        "time17": "28**",
        "time18": "28**",
        "time19": "28**",
        "time20": "29**",
        "time21": "29**",
        "time22": "29**",
        "time23": "31**",
        "time24": "30**"
    },
    {
        "state": "KEDAH",
        "location": "Alor Setar",
        "time1": "29**",
        "time2": "28**",
        "time3": "28**",
        "time4": "30**",
        "time5": "31**",
        "time6": "30**",
        "time7": "29**",
        "time8": "28**",
        "time9": "29**",
        "time10": "29**",
        "time11": "28**",
        "time12": "29**",
        "time13": "28**",
        "time14": "29**",
        "time15": "29**",
        "time16": "29**",
        "time17": "30**",
        "time18": "30**",
        "time19": "29**",
        "time20": "30**",
        "time21": "30**",
        "time22": "31**",
        "time23": "32**",
        "time24": "31**"
    },
    {
        "state": "KEDAH",
        "location": "Sungai Petani",
        "time1": "44**",
        "time2": "44**",
        "time3": "44**",
        "time4": "41**",
        "time5": "43**",
        "time6": "43**",
        "time7": "44**",
        "time8": "45**",
        "time9": "46**",
        "time10": "47**",
        "time11": "46**",
        "time12": "45**",
        "time13": "45**",
        "time14": "45**",
        "time15": "45**",
        "time16": "45**",
        "time17": "44**",
        "time18": "45**",
        "time19": "44**",
        "time20": "45**",
        "time21": "45**",
        "time22": "45**",
        "time23": "45**",
        "time24": "45**"
    },
    {
        "state": "KEDAH",
        "location": "Kulim Hi-Tech",
        "time1": "37**",
        "time2": "38**",
        "time3": "39**",
        "time4": "38**",
        "time5": "37**",
        "time6": "37**",
        "time7": "37**",
        "time8": "37**",
        "time9": "37**",
        "time10": "37**",
        "time11": "38**",
        "time12": "38**",
        "time13": "36**",
        "time14": "36**",
        "time15": "36**",
        "time16": "37**",
        "time17": "35**",
        "time18": "36**",
        "time19": "38**",
        "time20": "37**",
        "time21": "37**",
        "time22": "37**",
        "time23": "39**",
        "time24": "38**"
    }
]