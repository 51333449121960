export const listofState = {
    "metadata": {
        "resultset": {
            "count": 16,
            "offset": 0,
            "limit": 50,
            "locationcategoryid": "STATE"
        }
    },
    "results": [
        {
            "id": "LOCATION:1",
            "name": "JOHOR",
            "locationcategoryid": "STATE",
            "latitude": 1.4655,
            "longitude": 103.7578
        },
        {
            "id": "LOCATION:2",
            "name": "KEDAH",
            "locationcategoryid": "STATE",
            "latitude": 6.12104,
            "longitude": 100.36014
        },
        {
            "id": "LOCATION:3",
            "name": "KELANTAN",
            "locationcategoryid": "STATE",
            "latitude": 6.05666,
            "longitude": 102.26451
        },
        {
            "id": "LOCATION:4",
            "name": "KUALA LUMPUR",
            "locationcategoryid": "STATE",
            "latitude": 3.1429996,
            "longitude": 101.6947998
        },
        {
            "id": "LOCATION:5",
            "name": "LABUAN",
            "locationcategoryid": "STATE",
            "latitude": 4.890934,
            "longitude": 114.942834
        },
        {
            "id": "LOCATION:6",
            "name": "MELAKA",
            "locationcategoryid": "STATE",
            "latitude": 2.2319263,
            "longitude": 102.294251
        },
        {
            "id": "LOCATION:7",
            "name": "NEGERI SEMBILAN",
            "locationcategoryid": "STATE",
            "latitude": 2.7297,
            "longitude": 101.9381
        },
        {
            "id": "LOCATION:8",
            "name": "PAHANG",
            "locationcategoryid": "STATE",
            "latitude": 3.8077,
            "longitude": 103.326
        },
        {
            "id": "LOCATION:9",
            "name": "PULAU PINANG",
            "locationcategoryid": "STATE",
            "latitude": 5.41123,
            "longitude": 100.33543
        },
        {
            "id": "LOCATION:10",
            "name": "PERAK",
            "locationcategoryid": "STATE",
            "latitude": 4.5841,
            "longitude": 101.0829
        },
        {
            "id": "LOCATION:12",
            "name": "PUTRAJAYA",
            "locationcategoryid": "STATE",
            "latitude": 2.91667,
            "longitude": 101.7
        },
        {
            "id": "LOCATION:13",
            "name": "SABAH",
            "locationcategoryid": "STATE",
            "latitude": 5.9749,
            "longitude": 116.0724
        },
        {
            "id": "LOCATION:14",
            "name": "SARAWAK",
            "locationcategoryid": "STATE",
            "latitude": 1.58333,
            "longitude": 110.33333
        },
        {
            "id": "LOCATION:15",
            "name": "SELANGOR",
            "locationcategoryid": "STATE",
            "latitude": 3.08507,
            "longitude": 101.53281
        },
        {
            "id": "LOCATION:16",
            "name": "TERENGGANU",
            "locationcategoryid": "STATE",
            "latitude": 5.3302,
            "longitude": 103.1408
        },
        {
            "id": "LOCATION:11",
            "name": "PERLIS",
            "locationcategoryid": "STATE",
            "latitude": 6.4407,
            "longitude": 100.1979
        }
    ]
}
export const listofDistrict =
{
    "metadata": {
        "resultset": {
            "count": 164,
            "offset": 0,
            "limit": 50,
            "locationcategoryid": "DISTRICT"
        }
    },
    "results": [
        {
            "id": "LOCATION:19",
            "name": "KLUANG",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 2.0251,
            "longitude": 103.3328
        },
        {
            "id": "LOCATION:603",
            "name": "TANGKAK",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 2.2732482,
            "longitude": 102.5433991
        },
        {
            "id": "LOCATION:20",
            "name": "KOTA TINGGI",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 1.7381,
            "longitude": 103.8999
        },
        {
            "id": "LOCATION:22",
            "name": "MERSING",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 2.4312,
            "longitude": 103.8405
        },
        {
            "id": "LOCATION:23",
            "name": "MUAR",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 2.0442,
            "longitude": 102.5689
        },
        {
            "id": "LOCATION:18",
            "name": "JOHOR BAHRU",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 1.4655,
            "longitude": 103.7578
        },
        {
            "id": "LOCATION:25",
            "name": "SEGAMAT",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 2.5148,
            "longitude": 102.8158
        },
        {
            "id": "LOCATION:26",
            "name": "PONTIAN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 1.51638,
            "longitude": 103.3839109
        },
        {
            "id": "LOCATION:339",
            "name": "KULAI",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 1.6626699,
            "longitude": 103.5979733
        },
        {
            "id": "LOCATION:17",
            "name": "BATU PAHAT",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:1",
            "latitude": 1.8548,
            "longitude": 102.9325
        },
        {
            "id": "LOCATION:36",
            "name": "SIK",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 6.03482,
            "longitude": 100.8603
        },
        {
            "id": "LOCATION:425",
            "name": "BANDAR BAHARU",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 5.1194376,
            "longitude": 100.5220948
        },
        {
            "id": "LOCATION:37",
            "name": "YAN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 5.7905,
            "longitude": 100.39426
        },
        {
            "id": "LOCATION:35",
            "name": "POKOK SENA",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 6.17055,
            "longitude": 100.51885
        },
        {
            "id": "LOCATION:34",
            "name": "PENDANG",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 5.98458,
            "longitude": 100.60732
        },
        {
            "id": "LOCATION:33",
            "name": "PADANG TERAP",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 6.24734,
            "longitude": 100.77145
        },
        {
            "id": "LOCATION:32",
            "name": "LANGKAWI",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 6.33333,
            "longitude": 99.75
        },
        {
            "id": "LOCATION:31",
            "name": "KOTA SETAR",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 6.13164,
            "longitude": 100.34853
        },
        {
            "id": "LOCATION:30",
            "name": "KULIM",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 5.32403,
            "longitude": 100.60851
        },
        {
            "id": "LOCATION:29",
            "name": "KUBANG PASU",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 6.40508,
            "longitude": 100.43982
        },
        {
            "id": "LOCATION:28",
            "name": "KUALA MUDA",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 5.56667,
            "longitude": 100.33333
        },
        {
            "id": "LOCATION:27",
            "name": "BALING",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:2",
            "latitude": 5.67806,
            "longitude": 100.9177
        },
        {
            "id": "LOCATION:46",
            "name": "PASIR PUTEH",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 5.83333,
            "longitude": 102.4
        },
        {
            "id": "LOCATION:41",
            "name": "KOTA BHARU",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 6.05666,
            "longitude": 102.26451
        },
        {
            "id": "LOCATION:42",
            "name": "KUALA KRAI",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 5.5313,
            "longitude": 102.19925
        },
        {
            "id": "LOCATION:40",
            "name": "JELI",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 5.71493,
            "longitude": 101.88418
        },
        {
            "id": "LOCATION:48",
            "name": "TUMPAT",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 6.19776,
            "longitude": 102.17098
        },
        {
            "id": "LOCATION:47",
            "name": "TANAH MERAH",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 5.66667,
            "longitude": 102.0
        },
        {
            "id": "LOCATION:44",
            "name": "MACHANG",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 5.76407,
            "longitude": 102.21414
        },
        {
            "id": "LOCATION:38",
            "name": "BACHOK",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 6.06667,
            "longitude": 102.4
        },
        {
            "id": "LOCATION:39",
            "name": "GUA MUSANG",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 4.8823,
            "longitude": 101.9644
        },
        {
            "id": "LOCATION:45",
            "name": "PASIR MAS",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:3",
            "latitude": 6.0424976,
            "longitude": 102.1427782
        },
        {
            "id": "LOCATION:49",
            "name": "KUALA LUMPUR",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:4",
            "latitude": 3.14309,
            "longitude": 101.68653
        },
        {
            "id": "LOCATION:669",
            "name": "FP LABUAN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:5",
            "latitude": 5.3333,
            "longitude": 115.2
        },
        {
            "id": "LOCATION:765",
            "name": "CENTRAL MELAKA",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:6",
            "latitude": null,
            "longitude": null
        },
        {
            "id": "LOCATION:53",
            "name": "MELAKA TENGAH",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:6",
            "latitude": 2.1674,
            "longitude": 102.3273
        },
        {
            "id": "LOCATION:51",
            "name": "ALOR GAJAH",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:6",
            "latitude": 2.3804,
            "longitude": 102.2089
        },
        {
            "id": "LOCATION:52",
            "name": "JASIN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:6",
            "latitude": 2.3096,
            "longitude": 102.4281
        },
        {
            "id": "LOCATION:56",
            "name": "KUALA PILAH",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 2.7389,
            "longitude": 102.2487
        },
        {
            "id": "LOCATION:58",
            "name": "REMBAU",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 2.5897,
            "longitude": 102.0913
        },
        {
            "id": "LOCATION:59",
            "name": "SEREMBAN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 2.7297,
            "longitude": 101.9381
        },
        {
            "id": "LOCATION:60",
            "name": "TAMPIN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 2.4701,
            "longitude": 102.2302
        },
        {
            "id": "LOCATION:54",
            "name": "JELEBU",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 3.0606425,
            "longitude": 102.133645
        },
        {
            "id": "LOCATION:55",
            "name": "JEMPOL",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 2.869634,
            "longitude": 102.4696778
        },
        {
            "id": "LOCATION:57",
            "name": "PORT DICKSON",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:7",
            "latitude": 2.5228,
            "longitude": 101.7959
        },
        {
            "id": "LOCATION:65",
            "name": "KUANTAN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:8",
            "latitude": 3.8077,
            "longitude": 103.326
        },
        {
            "id": "LOCATION:61",
            "name": "BENTONG",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:8",
            "latitude": 3.413179,
            "longitude": 102.0466376
        },
        {
            "id": "LOCATION:66",
            "name": "LIPIS",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:8",
            "latitude": 4.3430119,
            "longitude": 101.8803489
        },
        {
            "id": "LOCATION:71",
            "name": "TEMERLOH",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:8",
            "latitude": 3.460309,
            "longitude": 102.4715911
        },
        {
            "id": "LOCATION:67",
            "name": "MARAN",
            "locationcategoryid": "DISTRICT",
            "locationrootid": "LOCATION:8",
            "latitude": 3.586,
            "longitude": 102.773
        }
    ]
}

export const listofTown = {
    "metadata": {
        "resultset": {
            "count": 155,
            "offset": 0,
            "limit": 50,
            "locationcategoryid": "TOWN"
        }
    },
    "results": [
        {
            "id": "LOCATION:127",
            "name": "MUAR",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.0442,
            "longitude": 102.5689
        },
        {
            "id": "LOCATION:126",
            "name": "TANGKAK",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.2673,
            "longitude": 102.5453
        },
        {
            "id": "LOCATION:125",
            "name": "LABIS",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.385,
            "longitude": 103.021
        },
        {
            "id": "LOCATION:124",
            "name": "JOHOR BAHRU",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.4655,
            "longitude": 103.7578
        },
        {
            "id": "LOCATION:122",
            "name": "AYER HITAM",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.915,
            "longitude": 103.1808
        },
        {
            "id": "LOCATION:123",
            "name": "BATU PAHAT",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.8548,
            "longitude": 102.9325
        },
        {
            "id": "LOCATION:131",
            "name": "MERSING",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.4312,
            "longitude": 103.8405
        },
        {
            "id": "LOCATION:659",
            "name": "ISKANDAR PUTERI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.4135905,
            "longitude": 103.6317194
        },
        {
            "id": "LOCATION:129",
            "name": "KLUANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.0251,
            "longitude": 103.3328
        },
        {
            "id": "LOCATION:768",
            "name": "NUSAJAYA",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.4239,
            "longitude": 103.6485
        },
        {
            "id": "LOCATION:130",
            "name": "KOTA TINGGI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.7381,
            "longitude": 103.8999
        },
        {
            "id": "LOCATION:134",
            "name": "PONTIAN",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.5,
            "longitude": 103.5
        },
        {
            "id": "LOCATION:135",
            "name": "SEGAMAT",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.5148,
            "longitude": 102.8158
        },
        {
            "id": "LOCATION:136",
            "name": "SENAI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.64131,
            "longitude": 103.66962
        },
        {
            "id": "LOCATION:137",
            "name": "SIMPANG RENGGAM",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.825,
            "longitude": 103.3107
        },
        {
            "id": "LOCATION:138",
            "name": "YONG PENG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.0136,
            "longitude": 103.0659
        },
        {
            "id": "LOCATION:133",
            "name": "PASIR GUDANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.462,
            "longitude": 103.9053
        },
        {
            "id": "LOCATION:439",
            "name": "KULAI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 1.532949,
            "longitude": 103.6450341
        },
        {
            "id": "LOCATION:128",
            "name": "PAGOH",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:1",
            "latitude": 2.1495,
            "longitude": 102.7704
        },
        {
            "id": "LOCATION:426",
            "name": "SERDANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 5.2093299,
            "longitude": 100.6119237
        },
        {
            "id": "LOCATION:139",
            "name": "ALOR STAR",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 6.12104,
            "longitude": 100.36014
        },
        {
            "id": "LOCATION:140",
            "name": "BALING",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 5.67806,
            "longitude": 100.9177
        },
        {
            "id": "LOCATION:141",
            "name": "JITRA",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 6.26812,
            "longitude": 100.42167
        },
        {
            "id": "LOCATION:142",
            "name": "KUALA NERANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 6.25844,
            "longitude": 100.61264
        },
        {
            "id": "LOCATION:143",
            "name": "KULIM",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 5.32403,
            "longitude": 100.60851
        },
        {
            "id": "LOCATION:145",
            "name": "PENDANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 5.98458,
            "longitude": 100.60732
        },
        {
            "id": "LOCATION:146",
            "name": "POKOK SENA",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 6.17055,
            "longitude": 100.51885
        },
        {
            "id": "LOCATION:147",
            "name": "SIK",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 6.03482,
            "longitude": 100.8603
        },
        {
            "id": "LOCATION:148",
            "name": "SUNGAI PETANI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 5.647,
            "longitude": 100.48772
        },
        {
            "id": "LOCATION:149",
            "name": "YAN",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:2",
            "latitude": 5.7905,
            "longitude": 100.39426
        },
        {
            "id": "LOCATION:154",
            "name": "KUALA KRAI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 5.5313,
            "longitude": 102.19925
        },
        {
            "id": "LOCATION:155",
            "name": "LOJING",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 4.7046137,
            "longitude": 101.5553148
        },
        {
            "id": "LOCATION:156",
            "name": "MACHANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 5.76407,
            "longitude": 102.21414
        },
        {
            "id": "LOCATION:158",
            "name": "PASIR PUTEH",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 5.83333,
            "longitude": 102.4
        },
        {
            "id": "LOCATION:160",
            "name": "TANAH MERAH",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 5.66667,
            "longitude": 102.0
        },
        {
            "id": "LOCATION:161",
            "name": "TUMPAT",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 6.19776,
            "longitude": 102.17098
        },
        {
            "id": "LOCATION:153",
            "name": "KOTA BHARU",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 6.05666,
            "longitude": 102.26451
        },
        {
            "id": "LOCATION:152",
            "name": "JELI",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 5.71493,
            "longitude": 101.88418
        },
        {
            "id": "LOCATION:151",
            "name": "GUA MUSANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 4.8823,
            "longitude": 101.9644
        },
        {
            "id": "LOCATION:150",
            "name": "BACHOK",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 6.06667,
            "longitude": 102.4
        },
        {
            "id": "LOCATION:159",
            "name": "RANTAU PANJANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 6.021325,
            "longitude": 101.9732687
        },
        {
            "id": "LOCATION:157",
            "name": "PASIR MAS",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:3",
            "latitude": 6.0424976,
            "longitude": 102.1427782
        },
        {
            "id": "LOCATION:169",
            "name": "SETAPAK",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.18333,
            "longitude": 101.7
        },
        {
            "id": "LOCATION:340",
            "name": "KUALA LUMPUR",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.1429996,
            "longitude": 101.6947998
        },
        {
            "id": "LOCATION:162",
            "name": "AMPANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.15,
            "longitude": 101.76667
        },
        {
            "id": "LOCATION:163",
            "name": "BANGSAR",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.12222,
            "longitude": 101.67606
        },
        {
            "id": "LOCATION:164",
            "name": "BUKIT BINTANG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 5.63052,
            "longitude": 102.6479
        },
        {
            "id": "LOCATION:165",
            "name": "CHERAS",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.05,
            "longitude": 101.76667
        },
        {
            "id": "LOCATION:166",
            "name": "JALAN DUTA",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.1484,
            "longitude": 101.6779
        },
        {
            "id": "LOCATION:167",
            "name": "KEPONG",
            "locationcategoryid": "TOWN",
            "locationrootid": "LOCATION:4",
            "latitude": 3.21667,
            "longitude": 101.63333
        }
    ]
}

export const forecastByLocation = {
    result:[
        {
            "minTemp": "24",
            "maxTemp": "33",
            "locationName": "KUALA LUMPUR",
            "longitude": "101.6947998",
            "latitude": "3.1429996",
            "morningAttribute": "No rain",
            "afternoonAttribute": "Isolated thunderstorms",
            "nightAttribute": "No rain",
            "date": "4/9/2023",
            "dayName": "Monday"
        },
        {
            "minTemp": "24",
            "maxTemp": "33",
            "locationName": "KUALA LUMPUR",
            "longitude": "101.6947998",
            "latitude": "3.1429996",
            "morningAttribute": "No rain",
            "afternoonAttribute": "Scattered thunderstorms",
            "nightAttribute": "No rain",
            "date": "5/9/2023",
            "dayName": "Tuesday"
        }
    ]
}

export const warningForecast = {
    "metadata": {
        "resultset": {
            "count": 2,
            "offset": 0,
            "limit": 50,
            "datasetid": "WARNING",
            "datacategoryid": "THUNDERSTORM"
        }
    },
    "results": [
        {
            "date": "2023-08-19T04:36:05.533777+00:00",
            "datatype": "THUNDERSTORM",
            "value": {
                "heading": {
                    "en": "Thunderstorms Warning",
                    "ms": "Amaran Ribut Petir"
                },
                "text": {
                    "en": {
                        "warning": "Thunderstorms, heavy rain and strong winds are expected over the states of Kedah (Kuala Muda and Baling); Penang; Perak (Larut, Matang and Selama); Selangor (Hulu Selangor, Gombak, Petaling, Hulu Langat and Sepang); FT Kuala Lumpur; FT Putrajaya; Negeri Sembilan (Jelebu, Seremban, Port Dickson, Kuala Pilah, Rembau and Tampin); Melaka; Johor; and Sabah: Sandakan (Beluran) and Kudat until 4:00PM; Saturday, 19 August 2023."
                    },
                    "ms": {
                        "warning": "Ribut petir, hujan lebat dan angin kencang dijangka di negeri Kedah (Kuala Muda dan Baling); Pulau Pinang; Perak (Larut, Matang Dan Selama); Selangor (Hulu Selangor, Gombak, Petaling, Hulu Langat dan Sepang); WP Kuala Lumpur; WP Putrajaya; Negeri Sembilan (Jelebu, Seremban, Port Dickson, Kuala Pilah, Rembau dan Tampin); Melaka; Johor; dan Sabah: Sandakan (Beluran) dan Kudat sehingga 4:00 petang; Sabtu, 19 Ogos 2023."
                    }
                }
            },
            "latitude": null,
            "longitude": null,
            "attributes": {
                "title": {
                    "en": "Thunderstorms Warning",
                    "ms": "Amaran Ribut Petir"
                },
                "ref": null,
                "timestamp": "2023-08-19T04:35:00+00:00",
                "valid_from": "2023-08-19T04:35:00+00:00",
                "valid_to": "2023-08-19T08:00:00+00:00"
            }
        },
        {
            "date": "2023-08-19T02:52:05.753129+00:00",
            "datatype": "THUNDERSTORM",
            "value": {
                "heading": {
                    "en": "Thunderstorms Warning",
                    "ms": "Amaran Ribut Petir"
                },
                "text": {
                    "en": {
                        "warning": "Thunderstorms, heavy rain and strong winds are expected over the states of Kedah (Kulim and Bandar Baharu); Penang (North Seberang Perai, Central Seberang Perai and South Seberang Perai); Selangor (Kuala Selangor, Klang, Kuala Langat and Sepang); FT Putrajaya; Negeri Sembilan (Seremban, Port Dickson, Rembau and Tampin); Melaka; and Johor (Tangkak, Pontian, Kulai and Johor Bahru) until 2:00PM; Saturday, 19 August 2023."
                    },
                    "ms": {
                        "warning": "Ribut petir, hujan lebat dan angin kencang dijangka di negeri Kedah (Kulim dan Bandar Baharu); Pulau Pinang (Seberang Perai Utara, Seberang Perai Tengah dan Seberang Perai Selatan); Selangor (Kuala Selangor, Klang, Kuala Langat dan Sepang); WP Putrajaya; Negeri Sembilan (Seremban, Port Dickson, Rembau dan Tampin); Melaka; dan Johor (Tangkak, Pontian, Kulai dan Johor Bahru) sehingga 2:00 petang; Sabtu, 19 Ogos 2023."
                    }
                }
            },
            "latitude": null,
            "longitude": null,
            "attributes": {
                "title": {
                    "en": "Thunderstorms Warning",
                    "ms": "Amaran Ribut Petir"
                },
                "ref": null,
                "timestamp": "2023-08-19T02:50:00+00:00",
                "valid_from": "2023-08-19T02:50:00+00:00",
                "valid_to": "2023-08-19T06:00:00+00:00"
            }
        }
    ]
}

export const datatypes = {
    "metadata": {
        "resultset": {
            "count": 58,
            "offset": 0,
            "limit": 50
        }
    },
    "results": [
        {
            "id": "FGM",
            "name": "FORECAST - GENERAL - DAILY MORNING",
            "datasetid": "FORECAST",
            "datacategoryid": "GENERAL"
        },
        {
            "id": "FGA",
            "name": "FORECAST - GENERAL - DAILY AFTERNOON",
            "datasetid": "FORECAST",
            "datacategoryid": "GENERAL"
        },
        {
            "id": "FGN",
            "name": "FORECAST - GENERAL - DAILY NIGHT",
            "datasetid": "FORECAST",
            "datacategoryid": "GENERAL"
        },
        {
            "id": "FMAXT",
            "name": "FORECAST - GENERAL - DAILY MAXIMUM TEMPERATURE",
            "datasetid": "FORECAST",
            "datacategoryid": "GENERAL"
        },
        {
            "id": "FMINT",
            "name": "FORECAST - GENERAL - DAILY MINIMUM TEMPERATURE",
            "datasetid": "FORECAST",
            "datacategoryid": "GENERAL"
        },
        {
            "id": "FSIGW",
            "name": "FORECAST - GENERAL - DAILY SIGNIFICANT WEATHER",
            "datasetid": "FORECAST",
            "datacategoryid": "GENERAL"
        },
        {
            "id": "WINDSEA",
            "name": "WARNING - STRONG WIND & ROUGH SEA",
            "datasetid": "WARNING",
            "datacategoryid": "WINDSEA"
        },
        {
            "id": "RAIN",
            "name": "WARNING - HEAVY RAIN",
            "datasetid": "WARNING",
            "datacategoryid": "RAIN"
        },
        {
            "id": "CYCLONE",
            "name": "WARNING - TROPICAL CYCLONE",
            "datasetid": "WARNING",
            "datacategoryid": "CYCLONE"
        },
        {
            "id": "QUAKETSUNAMI",
            "name": "ALERT - EARTHQUAKE/TSUNAMI",
            "datasetid": "WARNING",
            "datacategoryid": "QUAKETSUNAMI"
        },
        {
            "id": "RADARMY",
            "name": "OBSERVATION - RADAR - MALAYSIA",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RADAR"
        },
        {
            "id": "RADARPENINSULAMY",
            "name": "OBSERVATION - RADAR - PENINSULA MALAYSIA",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RADAR"
        },
        {
            "id": "RADARSABAHSARAWAK",
            "name": "OBSERVATION - RADAR - SABAH & SARAWAK",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RADAR"
        },
        {
            "id": "SATHIMAWARI",
            "name": "OBSERVATION - SATELLITE - HIMAWARI-8 ASEAN INFRA RED BAND",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RADAR"
        },
        {
            "id": "SATFY2G",
            "name": "OBSERVATION - SATELLITE - FENG YUN 2G ASEAN INFRA RED BAND",
            "datasetid": "OBSERVATION",
            "datacategoryid": "SATELLITE"
        },
        {
            "id": "SATNOAA",
            "name": "OBSERVATION - SATELLITE - NOAA PENINSULA & SUMATERA",
            "datasetid": "OBSERVATION",
            "datacategoryid": "SATELLITE"
        },
        {
            "id": "SATTERRAAQUA",
            "name": "OBSERVATION - SATELLITE - TERRA/AQUA HOTSPOT PENINSULA",
            "datasetid": "OBSERVATION",
            "datacategoryid": "SATELLITE"
        },
        {
            "id": "FMM",
            "name": "FORECAST - MARINE - DAILY MORNING",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "FMA",
            "name": "FORECAST - MARINE - DAILY AFTERNOON",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "FMN",
            "name": "FORECAST - MARINE - DAILY NIGHT",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "FMSIGW",
            "name": "FORECAST - MARINE - DAILY SIGNIFICANT WEATHER",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "FMWD",
            "name": "FORECAST - MARINE - WIND DIRECTION",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "FMWS",
            "name": "FORECAST - MARINE - WIND SPEED",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "FMWH",
            "name": "FORECAST - MARINE - WAVE HEIGHT",
            "datasetid": "FORECAST",
            "datacategoryid": "MARINE"
        },
        {
            "id": "WINDSEA2",
            "name": "WARNING - STRONG WIND & ROUGH SEA",
            "datasetid": "WARNING",
            "datacategoryid": "WINDSEA2"
        },
        {
            "id": "CYCLONE2",
            "name": "WARNING - TROPICAL CYCLONE",
            "datasetid": "WARNING",
            "datacategoryid": "CYCLONE2"
        },
        {
            "id": "QUAKETSUNAMI2",
            "name": "ALERT - EARTHQUAKE/TSUNAMI",
            "datasetid": "WARNING",
            "datacategoryid": "QUAKETSUNAMI2"
        },
        {
            "id": "THUNDERSTORM2",
            "name": "WARNING - THUNDERSTORM",
            "datasetid": "WARNING",
            "datacategoryid": "THUNDERSTORM2"
        },
        {
            "id": "RAIN_1HR",
            "name": "OBSERVATION - HOURLY OBSERVATION - RAIN_1HR",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "TEMP",
            "name": "OBSERVATION - HOURLY OBSERVATION - TEMPERATURE",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "RH",
            "name": "OBSERVATION - HOURLY OBSERVATION - RELATIVE HUMIDITY",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "WND_DIR",
            "name": "OBSERVATION - HOURLY OBSERVATION - WIND DIRECTION",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "WND_SPD",
            "name": "OBSERVATION - HOURLY OBSERVATION - WIND SPEED",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "VIS",
            "name": "OBSERVATION - HOURLY OBSERVATION - VISIBILITY",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "PRST_WX_ID",
            "name": "OBSERVATION - HOURLY OBSERVATION - PRESENT WEATHER ID",
            "datasetid": "OBSERVATION",
            "datacategoryid": "HOURLY"
        },
        {
            "id": "RMAXT",
            "name": "OBSERVATION - RAINS - MAX TEMPERATURE",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "THUNDERSTORM",
            "name": "WARNING - THUNDERSTORM",
            "datasetid": "WARNING",
            "datacategoryid": "THUNDERSTORM"
        },
        {
            "id": "RAIN2",
            "name": "WARNING - HEAVY RAIN",
            "datasetid": "WARNING",
            "datacategoryid": "RAIN2"
        },
        {
            "id": "RAINS",
            "name": "OBSERVATION - RAINS - RAINS",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS10m",
            "name": "OBSERVATION - RAINS - RAINS + 10m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS20m",
            "name": "OBSERVATION - RAINS - RAINS + 20m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS30m",
            "name": "OBSERVATION - RAINS - RAINS + 30m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS40m",
            "name": "OBSERVATION - RAINS - RAINS + 40m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS50m",
            "name": "OBSERVATION - RAINS - RAINS + 50m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS60m",
            "name": "OBSERVATION - RAINS - RAINS + 60m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS70m",
            "name": "OBSERVATION - RAINS - RAINS + 70m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS80m",
            "name": "OBSERVATION - RAINS - RAINS + 80m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS90m",
            "name": "OBSERVATION - RAINS - RAINS + 90m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS100m",
            "name": "OBSERVATION - RAINS - RAINS + 100m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS110m",
            "name": "OBSERVATION - RAINS - RAINS + 110m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        },
        {
            "id": "RAINS120m",
            "name": "OBSERVATION - RAINS - RAINS + 120m",
            "datasetid": "OBSERVATION",
            "datacategoryid": "RAINS"
        }
    ]
}
