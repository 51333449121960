import React, { Component } from 'react';
import Grid from '../../../../node_modules/semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import { Label, Segment } from 'semantic-ui-react';
import { datatypes, forecastByLocation, listofDistrict, listofState, listofTown, warningForecast } from '../jsons/WeatherForecast';
import { last24hours, last24hoursbystate } from '../jsons/APIM';
export class WeatherServiceAPIBoard extends Component {
    static displayName = WeatherServiceAPIBoard.name;

    constructor(props) {

    super(props);
      this.state = {
          listofStates: JSON.stringify(listofState),
          listofDistrict: JSON.stringify(listofDistrict),
          listofTown: JSON.stringify(listofTown),
          forecastByLocationUrl: " /WeatherForecast/forecast/location/{locationId}?start_date={start_date}&end_date={end_date}",
          forecastByLocationRespond: JSON.stringify(forecastByLocation),
          warningForecastUrl: "/WeatherForecast/warning/{warning_type}?start_date={start_date}&end_date={end_date}",
          warningForecast: JSON.stringify(warningForecast),
          datatypes: JSON.stringify(datatypes),
          last24hours: JSON.stringify(last24hours),
          last24hoursByStateUrl: "/apim/caqm/last24hours/state/{state}",
          last24hoursByState: JSON.stringify(last24hoursbystate)
      };

  }

    render() {

      return (
          <Grid style={{ justifyContent: 'center', margin: '2%' }}>
              <Grid.Row>
                  <Grid.Column width={16} style={{ textAlign: 'center', height: 'fit-content' }}>
                      <h1>Malaysian Weather Forecast Service API</h1>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <Label as='a' color='purple' tag style={{ marginRight: '1%' }}>
                          Asp .Net Core
                      </Label>
                      <Label as='a' color='blue' tag style={{ marginRight: '1%' }}>
                          Heroku
                      </Label>
                      <Label as='a' color='red' tag style={{ marginRight: '1%' }}>
                          JSON
                      </Label>
                      <Label as='a' color='yellow' tag>
                          Web Rest API
                      </Label>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <p>This Web Service API (Application Programming Interface) is a free service offered by Nami Studio to the general public. This API was fetched from
                          Malaysian Meteorological Department exposes endpoints with JSON response. We offered general forecast data without any user authentication. This service was deployed to Heroku.
                          Get started with  <a href="https://api.met.gov.my/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> MET API.</a>
                      </p>
                      <p> To start integrate this Malaysian Weather Forecast Service API, follow the documentation below.
                      </p>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16} style={{ textAlign: 'center' }}>
                      <h1>Documentation</h1>
                  </Grid.Column>
                  <Grid.Column width={16}>
                      <h3>Base Url</h3>
                      <Segment Raised>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com</Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <h3>Data Interchange Format</h3>
                      <Segment Raised>The supported data interchange format is JSON.</Segment>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                  <Grid.Column width={16}>
                      <h3>Weather Forecast</h3>
                      <p> Get list of states in Malaysia</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          /WeatherForecast/listofStates
                          <h4>Parameter</h4>
                          <Segment Raised>
                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.listofStates}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <p> Get list of district in Malaysia</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          /WeatherForecast/listofDistrict
                          <h4>Parameter</h4>
                          <Segment Raised>
                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.listofDistrict}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <p> Get list of town in Malaysia</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          /WeatherForecast/listofTown
                          <h4>Parameter</h4>
                          <Segment Raised>
                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.listofTown}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <p> Get weather forecast by location</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          {this.state.forecastByLocationUrl}
                          <h4>Parameter</h4>
                          <Segment Raised>
                              Where:
                              <div>
                                  locationId : location identifier , for example LOCATION:4
                              </div>
                              <div>
                                  start_date : start date must be from today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                              </div>
                              <div>
                                  end_date : end date must be equal or after today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                              </div>
                          </Segment>
                          <h4>Example</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com/WeatherForecast/forecast/location/LOCATION:4?start_date=8/19/2023&end_date=8/20/2023</code>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.forecastByLocationRespond}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <p> Get datatypes</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          /WeatherForecast/datatypes
                          <h4>Parameter</h4>
                          <Segment Raised>
                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.datatypes}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <h3>General Warning</h3>
                      <p> Get list of states in Malaysia</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          {this.state.warningForecastUrl}
                          <h4>Parameter</h4>
                          <Segment Raised>
                              Where:
                              <div>
                                  warning_type : is a type of warning available in Malaysia. There are 5 types of warning
                                  <li style={{ color: 'black', marginLeft: '2%' }}>quaketsunami</li>
                                  <li style={{ color: 'black', marginLeft: '2%' }}>rain</li>
                                  <li style={{ color: 'black', marginLeft: '2%' }}>thunderstorm</li>
                                  <li style={{ color: 'black', marginLeft: '2%' }}>cyclone</li>
                                  <li style={{ color: 'black', marginLeft: '2%' }}>windsea2</li>
                              </div>
                              <div>
                                  start_date : start date must be from today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                              </div>
                              <div>
                                  end_date : end date must be equal or after today date and must be in this format (mm/dd/yyyy), for example 8/19/2023.
                              </div>
                          </Segment>
                          <h4>Example</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com/WeatherForecast/warning/thunderstorm?start_date=8/19/2023&end_date=8/20/2023</code>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.warningForecast}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                  <Grid.Column width={16}>
                      <h3>Air Pollutant Index</h3>
                      <p> Get Air Pollutant index for last 24 hours for all state</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          /apim/caqm/last24hours/all
                          <h4>Parameter</h4>
                          <Segment Raised>
                              <Label color='red' style={{ marginRight: '1%' }}>Not Available</Label>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.last24hours}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <p> Get Air Pollutant index for last 24 hours by state</p>
                      <Segment Raised>
                          <Label color='purple' style={{ marginRight: '1%' }}>GET</Label>
                          {this.state.last24hoursByStateUrl}
                          <h4>Parameter</h4>
                          <Segment Raised>
                              Where:
                              <div>
                                  state : name of any state in Malaysia, for example: kedah.
                              </div>
                          </Segment>
                          <h4>Example</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>https://weather-forecast-service-dev-edbf78d02659.herokuapp.com/apim/caqm/last24hours/state/kedah</code>
                          </Segment>
                          <h4>Response</h4>
                          <Segment Raised>
                              <code style={{ color: 'black' }}>{this.state.last24hoursByState}</code>
                          </Segment>
                      </Segment>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
    );
  }
}
