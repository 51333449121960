import React, { Component } from 'react';
import btn_about_us from '../images/btn_about_us.png';
import icon_facebook from '../images/icon_facebook.png';
import icon_instagram from '../images/icon_instagram.png';
import icon_play_store from '../images/icon_play_store.png';
import icon_linkedin from '../images/icon_linkedin.png';
import { CustomFooter } from './CustomFooter';

export class ContactUsBoard extends Component {
  static displayName = ContactUsBoard.name;


  render() {
    return (
        <div>
            <div class="section-full">
                <section class="paddingAbout">
                    <h1 class="roboto-bold" style={{ textAlign: 'center' }}>Contact <font color="#FF001F">US</font></h1>
                    <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>

                    <div class="roboto-medium" style={{ textAlign: 'center', padding:20 }}>
                        <h3>Have an exciting project you need help with? Help us to improve our ability.</h3>
                    </div>

                    <div class="roboto-medium" style={{ textAlign: 'center', padding: 20 }}>
                        <h3>Wanna to join our team for fun?
                            Doesn't matter what is your experience. Let's have fun together.</h3>
                    </div>

                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <table class="centerTable">
                        <tr>
                            <td>
                                <a href="https://www.facebook.com/profile.php?id=100085342825720" target="_blank" rel="noreferrer">
                                    <img class="scaleSocMedLogo" src={icon_facebook} alt="icon_facebook"></img>
                                </a>
                                <a href="https://www.instagram.com/namistudio.my/" target="_blank" rel="noreferrer">
                                    <img class="scaleSocMedLogo" src={icon_instagram} alt="icon_instagram"></img>
                                </a>
                                <a href="https://play.google.com/store/apps/developer?id=Nami+Apps" target="_blank" rel="noreferrer">
                                    <img class="scaleSocMedLogo" src={icon_play_store} alt="icon_play_store"></img>
                                </a>
                                <a href="https://www.linkedin.com/company/nami-studio" target="_blank" rel="noreferrer">
                                    <img class="scaleSocMedLogo" src={icon_linkedin} alt="icon_linkedin"></img>
                                </a>
                            </td>
                        </tr>
                    </table>
                </section>

                <CustomFooter />

            </div>
        </div>
    );
  }
}
