import React, { Component } from 'react';
import mytvmovie_img from '../images/mytvmovie_img.png';
import dbsqlxmlconverter_img from '../images/dbsqlxmlconverter_img.png';
import nchatapp_img from '../images/nchatapp_img.png';
import api_img from '../images/api_img.jpg';
import simpleCalculator_img from '../images/simplecalculator.png';
import simpleTodo_img from '../images/simple_todo.png';
import malaysiaweatherforecast_img from '../images/malaysiaweatherforecast_img.png';
import Grid from '../../../../node_modules/semantic-ui-react/dist/commonjs/collections/Grid/Grid';

export class PersonalProjectBoard extends Component {
    static displayName = PersonalProjectBoard.name;

    render() {
        return (
            <Grid style={{ justifyContent: 'center' }}>
                <Grid.Row>
                    <Grid.Column width={5} style={{ textAlign: 'center', height: 'fit-content' }} >
                        <a href="#/personal-project/malaysia-weather-forecast">
                            <img src={malaysiaweatherforecast_img} alt="malaysiaweatherforecast_img" style={{ width: '100%', height: '100%' }} />
                            <p> Xamarin | Web Rest API</p>
                            <p>NAMI Malaysia Weather Forecast</p>
                        </a>
                    </Grid.Column>
                    <Grid.Column width={5} style={{ textAlign: 'center', height: 'fit-content' }} >
                        <a href="#/personal-project/weather-service-api">
                            <img src={api_img} alt="api_img" style={{ width: '100%', height: '100%' }} />
                            <p>ASP.Net Core | Heroku</p>
                            <p>Malaysian Weather Forecast Service API </p>
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5} style={{ textAlign: 'center', height:'fit-content' }} >
                        <a href="#/personal-project/my-tv-movie">
                            <img src={mytvmovie_img} alt="mytvmovie_img" style={{ width: '100%', height: '100%' }} />
                            <p> Xamarin | Web Rest API</p>
                            <p>MYTVMOVIE</p>
                        </a>
                    </Grid.Column>
                    <Grid.Column width={5} style={{ textAlign: 'center', height: 'fit-content' }} >
                        <a href="#/personal-project/dbsqlxml-converter">
                            <img src={dbsqlxmlconverter_img} alt="dbsqlxmlconverter_img" style={{ width: '100%', height: '100%' }} />
                            <p>Xamarin </p>
                            <p>DBSQLXML Converter</p>
                        </a>
                    </Grid.Column>
                    <Grid.Column width={5} style={{ textAlign: 'center', height: 'fit-content' }} >
                        <a href="#/personal-project/nchat-app">
                            <img src={nchatapp_img} alt="nchatapp_img" style={{ width: '100%', height: '100%' }} />
                            <p>Xamarin | Firebase</p>
                            <p>NCHAT APP</p>
                        </a>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={7} style={{ textAlign: 'center', height: 'fit-content' }} >
                        <a href="#/personal-project/simple-calculator">
                            <img src={simpleCalculator_img} alt="simpleCalculator_img" style={{ width: '40%', height: '40%' }} />
                            <p> Xamarin</p>
                            <p>Simple Calculator</p>
                        </a>
                    </Grid.Column>
                    <Grid.Column width={8} style={{ textAlign: 'center', height: 'fit-content' }} >
                        <a href="#/personal-project/simple-todo">
                            <img src={simpleTodo_img} alt="simpleTodo_img" style={{ width: '100%', height: '100%' }} />
                            <p> Xamarin</p>
                            <p>Simple Todo Tasks</p>
                        </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
        );
    }
}
