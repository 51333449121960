import React, { Component } from 'react';
import mytvmovie_img from '../../images/mytvmovie_img.png';
import ss1 from '../../images/Mytvmovie/ss1.png';
import ss2 from '../../images/Mytvmovie/ss2.png';
import ss3 from '../../images/Mytvmovie/ss3.png';
import ss4 from '../../images/Mytvmovie/ss4.png';
import ss5 from '../../images/Mytvmovie/ss5.png';
import ss6 from '../../images/Mytvmovie/ss6.png';
import { CustomFooter } from '../CustomFooter';

export class MyTVMovie extends Component {
    static displayName = MyTVMovie.name;
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return (
                <div>
              <div class="section-full">
                  <div class="paddingOurWorkDetail">
                      <section>
                          <img class="scaleOurWorkImage" src={mytvmovie_img} alt="mytvmovie_img" />
                            <h1 class="roboto-medium" style={{ color: 'white' }}>MYTV Movie</h1>
                      </section>

                      <table id="tableWithoutHeader">
                          <tr>
                              <td>Technology used:</td>
                              <td>Xamarin, C#, Web Rest API</td>
                          </tr>
                          <tr>
                              <td>Description:</td>
                              <td>Explore your favorite movies, tv shows and people anywhere.

                                  This apps was developed with Xamarin.  All the information was fetched from TMDB. The TMDB API service is for those interested in using the movie,
                            TV show/season, or actor data and, if required, images in their application. Get started with
                                    <a href="https://developer.themoviedb.org/reference/intro/getting-started" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> TMDB. </a>
                                </td></tr>
                          <tr>
                                <td colSpan="2" style={{ textAlign: 'center' }}> <a class="ourWorkLink" href="https://play.google.com/store/apps/details?id=com.nami.mytvmovie" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                  Available on Play Store
                              </a></td>
                          </tr>
                      </table>
                  </div>

                  <div class="paddingOurWorkScreenShort">
                      <h1 class="roboto-bold" style={{ textAlign: 'center' }}>SCREEN <font color="#FF001F">SHORT</font></h1>
                      <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                      <div class="row" style={{ placeContent: 'center' }} >
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss1} alt="ss1" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss2} alt="ss2" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss3} alt="ss3" /></td>
                                  </tr>
                              </div>
                          </div>
                      </div>

                      <div class="row" style={{ placeContent: 'center' }} >
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss4} alt="ss4" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss5} alt="ss5" /></td>
                                  </tr>
                              </div>
                          </div>
                          <div class="col-12 col-md-4" style={{ alignSelf: 'center' }}>
                              <div>
                                  <tr>
                                      <td> <img class="scaleOurWorkImage" src={ss6} alt="ss6" /></td>
                                  </tr>
                              </div>
                          </div>
                      </div>

                  </div>

                    <CustomFooter />
              </div>
          </div>

        );
    }
}
